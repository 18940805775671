import React from "react";

const Button = ({
  margin,
  width = "",
  height = "",
  border,
  background,
  isimg = false,
  text,
  fonttype,
  textcolor,
  padding,
  label,
  imgsrc,
  onClick,
  style = ""
}) => {
  return (
    <div>
      {!isimg && (
        <div className={margin}>
          <button
            className={`${width}  ${border}  ${background} ${padding} ${text} ${fonttype} ${textcolor} ${style} `}
            onClick={onClick}
          >
            {label}
          </button>
        </div>
      )}
      {isimg && (
        <div>
          <button
            className={`flex flex-row items-center justify-center space-x-2 rounded-2xl border-2 border-grey-9 ${width} ${height} `}
            onClick={onClick}
          >
            <img src={imgsrc} alt="g-logo"></img>
            <p className="font-InterSemibold text-ft12-16">{label}</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default Button;
