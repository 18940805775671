import React, { useState, useEffect } from "react";
import { MoonLoader } from "react-spinners";
import { sessionManager } from "../../../managers/sessionManager";
import { httpConstants } from "../../../constants";

const Editor = () => {
  const pathName = window.location.href;

  const idMatch = pathName.match(/\/editor\/([a-zA-Z0-9]+)\/([a-zA-Z0-9]+)$/);

  const templateId = idMatch ? idMatch[1] : null;
  const projectId = idMatch ? idMatch[2] : null;

  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const accessToken = sessionManager.getDataFromCookies(
    httpConstants.HEADERS.AUTH0_ID_TOKEN
  );
  const projectDetails = JSON.parse(localStorage.getItem("projectDetails"));

  // Define the function outside useEffect so it can be reused
  const sendTokenToIframe = () => {
    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { accessToken, projectDetails },
        // "http://localhost:5173"
        process.env.REACT_APP_TEMPLATE_EDITOR_URL
      );
    }
  };

  // Define the function to handle iframe load
  const handleIframeLoad = () => {
    setIsEditorLoading(false);
    // Add a small delay to ensure the iframe listener is ready
    setTimeout(sendTokenToIframe, 100);
  };

  useEffect(() => {
    // Listen for messages from the iframe
    const messageHandler = (event) => {
      // if (event.origin === "http://localhost:3000") {
      if (event.origin === process.env.REACT_APP_USER_WEBAPP_URL) {
        sendTokenToIframe();
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, projectDetails]);

  return (
    <div className="flex size-full items-center justify-center">
      <iframe
        className="size-full"
        // src={`http://localhost:5173/${templateId}/${projectId}`}
        src={`${process.env.REACT_APP_TEMPLATE_EDITOR_URL}${templateId}/${projectId}`}
        title="Editor"
        onLoad={handleIframeLoad}
      />
      {isEditorLoading && (
        <MoonLoader
          loading={isEditorLoading}
          color="#17C471"
          cssOverride={{
            position: "absolute",
            zIndex: 1000,
            top: "45%",
            left: "50%"
          }}
        />
      )}
    </div>
  );
};

export default Editor;
