import React from "react";
import Footer from "./common/components/footer";
import Sidebar from "./common/components/sideBar";

const Layout = ({ element: Element }) => {
  return (
    <div className="flex w-full">
      <Sidebar />
      <div className="flex h-screen w-85per flex-col justify-between overflow-y-auto bg-grey-18 pb-4">
        <Element />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
