import { createSlice } from "@reduxjs/toolkit";
import Utils from "../utility";
import { sessionManager } from "../managers/sessionManager";
import { httpConstants } from "../constants";

const initialState = {
  isLoggedIn: false,
  userId: "",
  email: "",
  firstName: "",
  lastName: "",
  organization: "",
  position: "",
  brandName: "",
  theme: {},
  businessModel: "",
  businessType: "",
  discovery: {},
  googleFonts: [],
  typeOfRole: "",
  brandList: [],
  info: JSON.parse(localStorage.getItem("info")) || {},
  loading: false,
  brandDetails: JSON.parse(localStorage.getItem("brandDetails")) || {},
  notificationApiCall: false,
  unreadNotificationCount:
    JSON.parse(localStorage.getItem("unreadNotificationCount")) || ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    updateUserDetail: (state, action) => {
      const updatedInfo = {
        ...state.info,
        ...action.payload
      };
      Utils.localStorageSetItem("info", updatedInfo);
      return {
        ...state,
        info: updatedInfo
      };
    },
    updateSubscriptionDetails: (state, action) => {
      const updatedSubscriptionDetails = {
        ...state.info.subscriptionDetails,
        ...action.payload
      };
      const updatedInfo = {
        ...state.info,
        subscriptionDetails: updatedSubscriptionDetails
      };
      Utils.localStorageSetItem("info", updatedInfo);
      return {
        ...state,
        info: updatedInfo
      };
    },
    showLoader: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    hideLoader: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    updateBrandsDetails: (state, action) => {
      Utils.localStorageSetItem("brandDetails", action.payload);
      return {
        ...state,
        brandDetails: action.payload
      };
    },
    removeUser: (state, action) => {
      sessionManager.removeDataFromCookies(
        httpConstants.HEADERS.AUTH0_ID_TOKEN
      );
      localStorage.clear();
      action.payload.isLoggedIn = false;
      return {
        ...state,
        ...action.payload
      };
    },
    callNotificationApi: (state) => {
      return {
        ...state,
        notificationApiCall: true
      };
    },
    stopNotificationApi: (state) => {
      return {
        ...state,
        notificationApiCall: false
      };
    },
    updateUnreadNotification: (state, action) => {
      const updatedUnreadCount = action.payload;
      Utils.localStorageSetItem("unreadNotificationCount", updatedUnreadCount);
      return {
        ...state,
        unreadNotificationCount: updatedUnreadCount
      };
    },
    // eslint-disable-next-line no-unused-vars
    removeUserDetail: (state, action) => {
      const updatedInfo = {};

      Utils.localStorageSetItem("info", updatedInfo);

      return {
        ...state,
        info: updatedInfo
      };
    }
  }
});

export const {
  updateUser,
  updateUserDetail,
  updateSubscriptionDetails,
  showLoader,
  hideLoader,
  updateBrandsDetails,
  removeUser,
  callNotificationApi,
  stopNotificationApi,
  updateUnreadNotification,
  removeUserDetail
} = userSlice.actions;

export default userSlice.reducer;
