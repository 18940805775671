import React from "react";
import { Navigate } from "react-router-dom";
import { httpConstants } from "./constants";
import { sessionManager } from "./managers/sessionManager";
import Layout from "./layout";

const PrivateRoute = ({ element: Element, isLayout = true }) => {
  const isAuthenticated = () => {
    const getToken = sessionManager.getDataFromCookies(
      httpConstants.HEADERS.AUTH0_ID_TOKEN
    );
    return !!getToken;
  };

  return isAuthenticated() ? (
    <div className="h-screen w-full">
      {isLayout ? <Layout element={Element} /> : <Element />}
    </div>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default PrivateRoute;
