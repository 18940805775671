import React from "react";
import { MoonLoader } from "react-spinners";

const CommonLoader = () => {
  return (
    <div className="absolute z-[100000000] flex h-screen w-full items-center justify-center">
      <MoonLoader loading={true} color="#17C471" />
    </div>
  );
};

export default CommonLoader;
