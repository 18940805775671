import React, { useState } from "react";
import { MoonLoader } from "react-spinners";
import UserDetails from "./userDetails";

const UserDetailsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [manage, setManage] = useState(false);

  return (
    <div
      className="w-full"
      onClick={() => {
        dropdown && setDropdown(false), manage && setManage(false);
      }}
    >
      <MoonLoader
        loading={loading}
        color="#17C471"
        cssOverride={{
          position: "absolute",
          zIndex: 1000,
          top: "45%",
          left: "50%"
        }}
      />
      <UserDetails
        setLoading={setLoading}
        dropdown={dropdown}
        setDropdown={setDropdown}
        manage={manage}
        setManage={setManage}
      />
    </div>
  );
};

export default UserDetailsComponent;
