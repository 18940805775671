/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
  METHOD_TYPE: { POST: "POST", PUT: "PUT", GET: "GET", DELETE: "DELETE" },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png"
  },
  DEVICE_TYPE: { WEB: "web" },
  API_END_POINT: {
    VERIFY_USER: "/api/user/verify-user",
    CREATE_USER: "/api/user/signUp",
    SEND_OTP: "/passwordless/start",
    VERIFY_OTP: "/oauth/token",
    SCRAP_UI: "/api/scrape",
    BRAND_DETAILS: "/api/brand/details",
    UPDATE_BRAND: "/api/brand",
    BRAND_LIST: "/api/brand/list",
    VERIFY_USER_SUB: "/api/user",
    CREATE_BRAND: "/api/brand/create",
    GET_FONTS: "/api/fonts/list",
    DELETE_BRAND: "/api/brand",
    SEARCH_BRAND: "/api/brand/search",
    UPLOAD_FILE: "/api/upload",
    UPDATE_USER: "/api/user/update",
    GET_IMAGES: "/api/images/random",
    IMAGE_URL: "api/upload/uploadByUrl",
    PROJECT_LIST: "/api/brand/project/list",
    DELETE_PROJECT: "/api/brand/project", // ID IN QUREY
    CREATE_PROJECT: "/api/brand/:BRAND_ID/project",
    GET_PROJECT_DETAILS: "/api/brand/project",
    UPDATE_PROJECT: "/api/brand/project/:PROJECT_ID",
    SEARCH_IMAGE_AI: "/api/images/search",
    PROMPT: "/api/ai/prompt",
    AD_DETAILS: "/api/ai/adDetails",
    REGENERATE_IMAGES: "/api/ai/regenerateImage",
    SEARCH_PROJECTS: "/api/brand/project/search",
    UPGRADE_SUBSCRIPTION: "/api/user/upgradeSubscription",
    CANCEL_SUBSCRIPTION: "/api/user/cancelSubscription",
    CREATE_CHECKOUT_SESSION: "/api/user/createCheckoutSession",
    INVOICE_LIST: "/api/user/invoicelist",
    INVOICE_URL: "api/user/invoiceUrl",
    NOTIFICATION: "/api/user/notification",
    MARK_NOTIFICATION_READ: "/api/user/notification/mark-read",
    UPDATE_CARD: "/api/user/update-card",
    CREATE_SESSION: "/api/user/session",
    ALL_TEMPLATES: "/api/brand/project/templates"
  },
  HEADERS: {
    AUTHORIZATION: "Authorization",
    ACCEPT: "accept",
    CONTENT_TYPE: "Content-Type",
    AUTH0_ID_TOKEN: "token"
  }
};

export const formConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE"
  }
};

export const keyConstants = {
  USER: "user",
  FIRST_BRAND_ID: "firstBrandId"
};
export const otpConstants = {
  EMAIL: "email",
  CODE: "code",
  OPEN_ID: "openid",
  SCOPE: "openid profile email",
  CLIENT_ID: "client_id",
  CLIENT_SECRET: "client_secret",
  GRANT_TYPE: "grant_type",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  BUSINESS: "company",
  BUSINESS_TYPE: "businessType",
  POSITION: "position",
  PROFILE: "profile",
  RESPONSE_TYPE: "response_type",
  REDIRECT_URI: "redirect_uri"
};

export const sidebarItems = [
  {
    name: "Dashboard",
    path: "/home/dashboard",
    activeIcon: "/images/home-icon-active.svg",
    inactiveicon: "/images/home-icon-inactive.svg",
    value: "dashboard"
  },
  {
    name: "Ad Library",
    path: "/ad-library",
    activeIcon: "/images/person-icon-active.svg",
    inactiveicon: "/images/person-icon-inactive.svg",
    value: "library"
  },
  {
    name: "Settings",
    path: "/settings",
    activeIcon: "/images/gear-icon-active.svg",
    inactiveicon: "/images/gear-icon-inactive.svg",
    value: "settings"
  },
  {
    name: "Support",
    path: "/support",
    activeIcon: "/images/question-icon-active.svg",
    inactiveicon: "/images/question-icon-inactive.svg",
    value: "support"
  }
];

export const redirects = [
  {
    name: "home",
    url: "https://www.adperfect.ai/"
  },
  {
    name: "contactUs",
    url: "https://www.adperfect.ai/"
  }
];

// constants.js
export const invoices = [
  {
    id: "MS-415646",
    date: 1721984813000,
    reference: "#MS-415646",
    amount: "$180",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "RV-126749",
    date: 1721984813000,
    reference: "#RV-126749",
    amount: "$250",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "FB-212562",
    date: 1721984813000,
    reference: "#FB-212562",
    amount: "$560",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "QW-103578",
    date: 1721984813000,
    reference: "#QW-103578",
    amount: "$120",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: 1721984813000,
    reference: "#AR-803481",
    amount: "$300",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  },
  {
    id: "AR-803481",
    date: "March 01, 2019",
    reference: "#AR-803481",
    amount: "$800",
    pdfUrl: "/images/pdf-icon.svg",
    pdfSelected: "/images/pdf-selected.svg"
  }
];

export const sections = [
  {
    title: "You'll lose this",
    items: [
      "10 credits per month",
      "Saved brands",
      "Saved projects",
      "Access to AI copywriter",
      "Access to AI designer"
    ]
  },
  {
    title: "You'll lose this",
    items: [
      "Unlimited photos from Unsplash API",
      "Competitor & Industry data & analysis for generating high-converting ads"
    ]
  }
];

export const BusinessModel = [
  {
    name: "profitable",
    value: [
      "Auto Shop",
      "Author / Speaker",
      "Coach / Course",
      "eCommerce",
      "Education",
      "Fashion",
      "Finance",
      "Fitness & Wellness",
      "Food & Beverage",
      "Healthcare",
      "Local Service (plumber, HVAC, etc.)",
      "Manufacturing / Factory",
      "Professional Service (accounting, management, marketing, lawyer, etc.)",
      "Real Estate",
      "Restaurants & Cafes",
      "Retail Shop",
      "Software as a Service",
      "Transportation Service",
      "Travel",
      "Utilities"
    ]
  },
  {
    name: "non-profit",
    value: ["Church", "Ministry", "NGO"]
  }
];

export const projectType = [
  {
    name: "Ad",
    value: "ad",
    activeIcon: "/images/ad-hover-icon.svg",
    inactiveicon: "/images/ad-icon.svg"
  },
  {
    name: "Social Post",
    value: "socialPost",
    activeIcon: "/images/social-post-hover-icon.svg",
    inactiveicon: "/images/socialPost.svg"
  }
];

export const platformTypeAds = [
  {
    name: "Google Ads",
    value: "Google",
    activeIcon: "/images/google-hover-icon.svg",
    inactiveicon: "/images/google-icon.svg"
  },
  {
    name: "Facebook Ad",
    value: "Facebook",
    activeIcon: "/images/facebook-hover-icon.svg",
    inactiveicon: "/images/facebook-icon.svg"
  },
  {
    name: "Instagram Ad",
    value: "Instagram",
    activeIcon: "/images/instagram-hover-icon.svg",
    inactiveicon: "/images/instagram-icon.svg"
  },
  {
    name: "Bing Ads",
    value: "Bing",
    activeIcon: "/images/bing-hover-icon.svg",
    inactiveicon: "/images/bing-icon.svg"
  },
  {
    name: "LinkedIn Ads",
    value: "LinkedIn",
    activeIcon: "/images/linkedin-hover-icon.svg",
    inactiveicon: "/images/linkedIn.svg"
  }
];
export const platformTypeSocial = [
  {
    name: "Instagram",
    value: "Instagram",
    activeIcon: "/images/instagram-hover-icon.svg",
    inactiveicon: "/images/instagram-icon.svg"
  },
  {
    name: "Facebook",
    value: "Facebook",
    activeIcon: "/images/facebook-hover-icon.svg",
    inactiveicon: "/images/facebook-icon.svg"
  },
  {
    name: "LinkedIn",
    value: "LinkedIn",
    activeIcon: "/images/linkedin-hover-icon.svg",
    inactiveicon: "/images/linkedIn.svg"
  }
];

export const googleAdsGoals = [
  {
    name: "Sales",
    value: "sales",
    activeIcon: "/images/ad-hover-icon.svg",
    inactiveicon: "/images/ad-icon.svg"
  },
  {
    name: "Lead Generation",
    value: "lead-generation",
    activeIcon: "/images/lead-generation-hover-icon.svg",
    inactiveicon: "/images/lead-generation.svg"
  },
  {
    name: "Website Traffic",
    value: "website-traffic",
    activeIcon: "/images/website-traffic-hover-icon.svg",
    inactiveicon: "/images/website-traffic.svg"
  },
  {
    name: "Product / Brand Consideration",
    value: "product-launch",
    activeIcon: "/images/product-launch-icon-hover.svg",
    inactiveicon: "/images/product-launch.svg"
  },
  {
    name: "Brand Awareness",
    value: "brand-awareness",
    activeIcon: "/images/brand-awareness-hover-icon.svg",
    inactiveicon: "/images/brand-awareness.svg"
  },
  {
    name: "App Promotion",
    value: "app-promotion",
    activeIcon: "/images/event-promo-hover-icon.svg",
    inactiveicon: "/images/event-promotion.svg"
  },
  {
    name: "Local Store Visits / Promotions",
    value: "local-visit",
    activeIcon: "/images/local-visit-hover-icon.svg",
    inactiveicon: "/images/local-visit.svg"
  },
  {
    name: "No Goal Guidance",
    value: "no-goal-guidance",
    activeIcon: "/images/donate-hover-icon.svg",
    inactiveicon: "/images/donations.svg"
  }
];
export const facebookAdsGoals = [
  {
    name: "Awareness",
    value: "awareness",
    activeIcon: "/images/brand-awareness-hover-icon.svg",
    inactiveicon: "/images/brand-awareness.svg"
  },
  {
    name: "Traffic",
    value: "traffic",
    activeIcon: "/images/website-traffic-hover-icon.svg",
    inactiveicon: "/images/website-traffic.svg"
  },
  {
    name: "Engagement",
    value: "engagement",
    activeIcon: "/images/engagement-hover-icon.svg",
    inactiveicon: "/images/engagement.svg"
  },
  {
    name: "Lead",
    value: "lead",
    activeIcon: "/images/lead-generation-hover-icon.svg",
    inactiveicon: "/images/lead-generation.svg"
  },
  {
    name: "App Promotion",
    value: "app-promotion",
    activeIcon: "/images/event-promo-hover-icon.svg",
    inactiveicon: "/images/event-promotion.svg"
  },
  {
    name: "Sales",
    value: "sales",
    activeIcon: "/images/ad-hover-icon.svg",
    inactiveicon: "/images/ad-icon.svg"
  }
];
export const linkedInAdsGoals = [
  {
    name: "Brand Awareness",
    value: "brand-awareness",
    activeIcon: "/images/brand-awareness-hover-icon.svg",
    inactiveicon: "/images/brand-awareness.svg"
  },
  {
    name: "Website Visit",
    value: "website-visit",
    activeIcon: "/images/website-traffic-hover-icon.svg",
    inactiveicon: "/images/website-traffic.svg"
  },
  {
    name: "Engagement",
    value: "engagement",
    activeIcon: "/images/engagement-hover-icon.svg",
    inactiveicon: "/images/engagement.svg"
  },
  {
    name: "Video views",
    value: "video-views",
    activeIcon: "/images/donate-hover-icon.svg",
    inactiveicon: "/images/donations.svg"
  },
  {
    name: "Lead Generation",
    value: "lead-generation",
    activeIcon: "/images/lead-generation-hover-icon.svg",
    inactiveicon: "/images/lead-generation.svg"
  },
  {
    name: "Website Conversions",
    value: "website-conversions",
    activeIcon: "/images/customer-retention-hover-icon.svg",
    inactiveicon: "/images/customer-retention.svg"
  },
  {
    name: "Job Applicants",
    value: "job-applicants",
    activeIcon: "/images/ad-hover-icon.svg",
    inactiveicon: "/images/ad-icon.svg"
  }
];

export const socialGoals = [
  {
    name: "Engagement",
    value: "engagement",
    activeIcon: "/images/engagement-hover-icon.svg",
    inactiveicon: "/images/engagement.svg"
  },
  {
    name: "Website Clicks",
    value: "website-clicks",
    activeIcon: "/images/website-traffic-hover-icon.svg",
    inactiveicon: "/images/website-traffic.svg"
  },
  {
    name: "Sales",
    value: "sales",
    activeIcon: "/images/ad-hover-icon.svg",
    inactiveicon: "/images/ad-icon.svg"
  }
];

export const uploadType = [
  {
    name: "Upload my own image",
    value: "ownImage",
    activeIcon: "/images/upload-own.svg",
    inactiveicon: "/images/upload-own.svg"
  },
  {
    name: "Use royalty-free image from library",
    value: "royaltyFreeImage",
    activeIcon: "/images/image-ad-selected.svg",
    inactiveicon: "/images/image-ad-unselected.svg"
  },
  {
    name: "Create AI- generated image",
    value: "aiGeneratedImage",
    activeIcon: "/images/ai-generation-active.svg",
    inactiveicon: "/images/ai-generation.svg"
  }
];

export const plansDataMonthly = [
  {
    title: "Freelance",
    amount: 12,
    planUrl: process.env.REACT_APP_FREELANCE_MONTHLY_PLAN,
    priceId: process.env.REACT_APP_FREELANCE_MONTHLY_PLAN_PRICE_ID,
    data: [
      "10 credits / month",
      "Access to AI copywriter",
      "Access to AI designer",
      "Unlimited photos from Unsplash API",
      "Access to support documentation"
    ],
    planTime: "month"
  },
  {
    title: "Business",
    amount: 24,
    planUrl: process.env.REACT_APP_BUSINESS_MONTHLY_PLAN,
    priceId: process.env.REACT_APP_BUSINESS_MONTHLY_PLAN_PRICE_ID,
    data: ["50 credits / month", "Access to email and ticket support"],
    planTime: "month"
  },
  {
    title: "Enterprise",
    amount: 50,
    planUrl: process.env.REACT_APP_ENTERPRISE_MONTHLY_PLAN,
    priceId: process.env.REACT_APP_ENTERPRISE_MONTHLY_PLAN_PRICE_ID,
    data: ["500 credits / month", "Dedicated account manager"],
    planTime: "month"
  }
];

export const plansDataAnnual = [
  {
    title: "Freelance",
    amount: 100,
    planUrl: process.env.REACT_APP_FREELANCE_ANNUAL_PLAN,
    priceId: process.env.REACT_APP_FREELANCE_ANNUAL_PLAN_PRICE_ID,
    data: [
      "10 credits / month",
      "Access to AI copywriter",
      "Access to AI designer",
      "Unlimited photos from Unsplash API",
      "Access to support documentation"
    ],
    planTime: "year"
  },
  {
    title: "Business",
    amount: 200,
    planUrl: process.env.REACT_APP_BUSINESS_ANNUAL_PLAN,
    priceId: process.env.REACT_APP_BUSINESS_ANNUAL_PLAN_PRICE_ID,
    data: ["50 credits / month", "Access to email and ticket support"],
    planTime: "year"
  },
  {
    title: "Enterprise",
    amount: 400,
    planUrl: process.env.REACT_APP_ENTERPRISE_ANNUAL_PLAN,
    priceId: process.env.REACT_APP_ENTERPRISE_ANNUAL_PLAN_PRICE_ID,
    data: [
      "500 credits / month",
      "No project limit",
      "Dedicated account manager"
    ],
    planTime: "year"
  }
];
export const aiGeneratedImagesUrls = [
  {
    id: 1,
    url: "https://d34i1ofvkn02mn.cloudfront.net/images/aiGenerated4.png"
  },
  {
    id: 2,
    url: "https://d34i1ofvkn02mn.cloudfront.net/images/aiGenerated3.png"
  },
  {
    id: 3,
    url: "https://d34i1ofvkn02mn.cloudfront.net/images/aiGenerated1.png"
  },
  {
    id: 4,
    url: "https://d34i1ofvkn02mn.cloudfront.net/images/aiGenerated2.png"
  }
];
