import React, { useState } from "react";
import FloatingLabel from "../inputs/FloatingLabel";
import { sections } from "../../../constants";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../slices/userSlice";
import { cancelSubscriptionPlan } from "../../../services/userService";
import Utils from "../../../utility";

const CancelPopup = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [cancelReason, setCancelReason] = useState("");
  const [betterNextTime, setBetterNextTime] = useState("");

  const handleCancelReasonChange = (e) => setCancelReason(e.target.value);
  const handleBetterNextTimeChange = (e) => setBetterNextTime(e.target.value);
  const closeCancelPopup = () => {
    dispatch(showLoader());
    setTimeout(() => {
      closeModal();
      dispatch(hideLoader());
    }, 500);
  };

  const cancelSubscription = async () => {
    try {
      dispatch(showLoader());
      const response = await cancelSubscriptionPlan({
        cancelAccountReason: cancelReason,
        userSuggestion: betterNextTime
      });
      if (response) {
        if (response?.responseCode === 200) {
          Utils.successToastMessage(
            "Your subscription plan has been successfully cancelled"
          );
          // Update the localStorage info object
          let storedInfo = JSON.parse(localStorage.getItem("info"));

          if (storedInfo && storedInfo.subscriptionDetails) {
            storedInfo.subscriptionDetails.isCancelled = true;
            localStorage.setItem("info", JSON.stringify(storedInfo));
          }

          closeModal();
        } else {
          Utils.failureToastMessage(
            "An issue occurred while cancelling the subscription plan"
          );
          return;
        }
      }
    } catch (err) {
      Utils.failureToastMessage(
        "An error occurred while cancelling the subscription plan"
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
    <div className="fixed inset-0 z-50 flex  items-center justify-center  bg-blurDark bg-opacity-50 backdrop-blur-sm">
      <div className="relative h-[846px] w-[988px] overflow-y-auto  rounded-lg bg-white p-8 font-InterRegular shadow-lg">
        <img
          src="/images/close-icon.svg"
          alt="Close"
          onClick={closeModal}
          className="absolute right-4 top-4 cursor-pointer text-grey-16 hover:text-grey-19"
        />

        <div className="-mt-2.5 mb-6 flex flex-col items-center justify-center text-center">
          <h1 className="mt-5  font-InterBold text-ft32-41.6 text-grey-24">
            Cancel Account
          </h1>
          <p className="mt-2 w-60per text-center font-InterRegular text-ft18-25.2 font-normal text-grey-14">
            Are you sure you want to cancel your account? <br></br> Here is what
            you will miss out on.
          </p>
        </div>

        <div className="mb-6 flex justify-center gap-10.2">
          {sections.map((section, index) => (
            <div
              key={index}
              className="h-58 w-[296px]  rounded-xl bg-white p-5 shadow-md"
            >
              <h2 className="text-ft18-25.2 font-semibold text-grey-14">
                {section.title}
              </h2>
              <div className="ml-6 mt-2">
                {section.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="mt-1.5 flex items-center text-ft16-22.4 text-grey-14"
                  >
                    {/* <CheckIcon className=" shrink-0 " /> */}
                    <img
                      src="/images/check-icon.svg"
                      alt="Check Icon"
                      className=" shrink-0"
                    />
                    <span className="ml-2">{item}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mb-6 flex flex-col items-center justify-center">
          <button
            className=" my-2.5 flex h-11.2 w-87.5 cursor-pointer  items-center justify-center rounded-2xl  bg-green-12 px-10 font-InterBold text-ft14-21 text-white"
            onClick={closeCancelPopup}
          >
            NEVERMIND, TAKE ME BACK
          </button>
          <div className=" mt-2 w-45per text-center font-InterRegular text-ft18-25.2 font-normal text-grey-14">
            If you are sure you still want to cancel, please answer a couple
            questions before you go.
          </div>
        </div>

        <div className="mb-6 text-center">
          <div className="flex flex-col items-center gap-8">
            <FloatingLabel
              label={"What is the main reason for leaving?"}
              name={"cancelReason"}
              height={"h-12"}
              width="w-[435px]"
              isStar={false}
              onBoard={true}
              isSmall={true}
              value={cancelReason}
              maxLength={250}
              onChange={handleCancelReasonChange}
            />
            <FloatingLabel
              label={"Is there anything that we could do better next time?"}
              name={"betterNextTime"}
              height={"h-12"}
              width="w-[435px]"
              isStar={false}
              onBoard={true}
              isSmall={true}
              value={betterNextTime}
              onChange={handleBetterNextTimeChange}
            />
          </div>
        </div>

        <div className="flex justify-center gap-8">
          <button
            disabled={cancelReason && betterNextTime ? false : true}
            className={`h-11.2 w-87.5 rounded-2xl ${cancelReason && betterNextTime ? "opacity-100" : "opacity-50"} bg-[#CF3A3A] px-6 py-2 text-ft14-21 font-bold text-white hover:bg-[#CF3A3A]`}
            onClick={cancelSubscription}
          >
            CANCEL ACCOUNT
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelPopup;
