import axios from "axios";
import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { httpConstants, otpConstants } from "../../constants";
import { verifyUser, verifyUserByAuth } from "../../services/userService";
import { MoonLoader } from "react-spinners";
import Utils from "../../utility";
import { sessionManager } from "../../managers/sessionManager";

const Callback = () => {
  const navigate = useNavigate();
  let callbackCode = window.location.href.split("?code=")[1].split("&state")[0];
  let domainUrl = `${process.env.REACT_APP_OTP_AUTH}/oauth/token`;
  let userEmail = localStorage.getItem("email");
  const { setDataInCookies } = sessionManager;

  const fetchDetails = async (token) => {
    const [error, response] = await Utils.parseResponse(
      verifyUserByAuth(token)
    );
    if (error || !response) {
      return error?.response?.data;
    }
    return response;
  };

  useEffect(() => {
    localStorage.setItem("isUserExists", true);
    (async () => {
      try {
        axios
          .post(domainUrl, {
            [otpConstants.GRANT_TYPE]: "authorization_code",
            [otpConstants.CLIENT_ID]: process.env.REACT_APP_OTP_CLIENT_ID,
            [otpConstants.CLIENT_SECRET]:
              process.env.REACT_APP_OTP_CLIENT_SECRET,
            [otpConstants.REDIRECT_URI]: process.env.REACT_APP_REDIRECT_URI,
            code: callbackCode
          })
          .then(async (resp) => {
            setDataInCookies(
              resp?.data?.access_token,
              httpConstants.HEADERS.AUTH0_ID_TOKEN,
              1
            );

            localStorage.setItem("id_token", resp?.data?.id_token);
            let decoded = jwtDecode(resp?.data?.id_token);
            localStorage.setItem("userDetails", JSON.stringify(decoded));
            const userInfo = await fetchDetails();
            if (!userInfo?.responseData) {
              navigate("/welcome");
              return;
            }
            localStorage.setItem(
              "info",
              JSON.stringify(userInfo?.responseData)
            );
            if (decoded.sub.includes("google")) {
              localStorage.setItem("socialLogin", "google");

              // Verify user for Google accounts
              const response = await verifyUser(decoded.email ?? userEmail);
              if (!response || !response.responseData) {
                return;
              }

              navigate(
                response.responseData.exists ? "/home/dashboard" : "/welcome"
              );
            } else {
              // Verify user by authentication
              try {
                localStorage.setItem("socialLogin", "facebook");

                const response = await verifyUserByAuth();
                if (!response) {
                  navigate("/welcome");
                  return;
                }

                navigate(
                  response.responseData ? "/home/dashboard" : "/welcome"
                );
              } catch (error) {
                navigate(
                  !error?.response?.data?.responseData
                    ? "/welcome"
                    : "/home/dashboard"
                );
              }
            }
          });
      } catch (error) {
        navigate("/");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MoonLoader
        loading={true}
        color="#17C471"
        cssOverride={{
          position: "absolute",
          zIndex: 1000,
          top: "45%",
          left: "50%"
        }}
      />
    </>
  );
};

export default Callback;
