/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState, useEffect } from "react";
import Utils from "../../../utility";
import {
  getProjectDetalis,
  updateProject
} from "../../../services/userService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../slices/userSlice";

const AiGeneratedPopup = ({
  setStep,
  setCurrentPopup,
  setProjectDetailState,
  projectDetailsState,
  projectDetailsGeneratedData,
  regenerateImages
}) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const currentUrl = window.location.href;
  const projectTemplateIds = currentUrl.split("/");
  const [projectDetails, setProjectDetalis] = useState({});
  const getProjectDetails = async (projectId) => {
    try {
      const [error, response] = await Utils.parseResponse(
        getProjectDetalis(projectId)
      );

      if (error || !response) {
        console.error("Error");
        return;
      }

      setProjectDetalis(response?.responseData);
    } catch (err) {
      console.error("An error occurred while fetching project details:", err);
    } finally {
      //
    }
  };
  useEffect(() => {
    if (projectTemplateIds[7]?.includes("true")) {
      getProjectDetails(projectTemplateIds[8]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTheAIGeneratedImagesPopup = () => {
    localStorage.setItem("projectDetails", JSON.stringify(projectDetailsState));
    setCurrentPopup("");
    setStep(4);
  };

  const updateProjectDetailsById = async () => {
    try {
      dispatch(showLoader());
      const requestData = {
        selectedImage: selectedImageUrl
      };
      // eslint-disable-next-line no-unused-vars
      const [error, response] = await Utils.parseResponse(
        updateProject(
          projectDetailsGeneratedData?.promptRes?._id || projectTemplateIds[8],
          requestData
        )
      );
      if (error) {
        if (error?.response?.data?.message) {
          Utils.failureToastMessage(error?.response?.data?.message);
          return;
        } else {
          Utils.failureToastMessage("Some error occured");
          return;
        }
      } else {
        handleTheAIGeneratedImagesPopup();
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleImageClick = (index, url) => {
    setSelectedImage(index);
    setSelectedImageUrl(url);
    setProjectDetailState((prev) => ({
      ...prev,
      selectedImage: url
    }));
  };
  const projectDetailsResponse =
    projectDetailsGeneratedData?.promptRes?.images || projectDetails?.images;
  return (
    <div className="fixed right-0 top-0 z-50 flex size-full items-center justify-center bg-blurDark px-15 backdrop-blur-sm">
      <div className="cursor-pointer rounded-15px bg-white px-20 pb-9 pt-13">
        <h1 className="text-center font-InterBold text-ft32-41.6 text-black-9">
          AI Generated Images
        </h1>
        <p className="mt-2.5 text-center font-InterRegular text-ft14-19.6 text-grey-14">
          You can choose from any of the AI-generated images below to use for
          your ad.
        </p>
        <div className="mt-10 grid grid-cols-2 gap-x-4.5 gap-y-6">
          {projectDetailsResponse?.length > 0 &&
            projectDetailsResponse.map((image, index) => (
              <div
                key={index}
                onClick={() => handleImageClick(index, image)}
                className="relative"
              >
                {selectedImage === index && (
                  <div className=" absolute left-2.5 top-2.5 flex size-10.2 items-center justify-center rounded-full bg-green-12 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-check size-5"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                )}
                <img
                  className={` h-285px w-567px rounded-25px object-cover ${
                    selectedImage === index ? " border-2px border-green-12" : ""
                  }`}
                  alt={`ai-images-${index}`}
                  src={image}
                />
              </div>
            ))}
        </div>
        <div className="ml-15 mt-5.5 flex items-center justify-center gap-8">
          <button
            onClick={regenerateImages}
            className="flex h-43px w-50 items-center justify-center rounded-15px border-2px border-grey-14 bg-white font-InterBold text-ft14-19.6 text-grey-14 shadow-buttonShadow"
          >
            REGENERATE IMAGES
          </button>
          <button
            disabled={
              selectedImage == 0 ||
              selectedImage == 1 ||
              selectedImage == 2 ||
              selectedImage == 3
                ? false
                : true
            }
            onClick={updateProjectDetailsById}
            className={`flex h-43px w-75 items-center ${
              selectedImage == 0 ||
              selectedImage == 1 ||
              selectedImage == 2 ||
              selectedImage == 3
                ? "opacity-100"
                : "opacity-50"
            } justify-center rounded-15px bg-green-12 font-InterBold text-ft14-19.6 text-white shadow-buttonShadow`}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default AiGeneratedPopup;
