/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import React, { useState, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../common/components/header";
import { getBrandDetails, getProjectList } from "../../services/userService";
import Utils from "../../utility";
import Project from "./project";
import UserBrandDetails from "../welcome/user/brandDetails";
import { useDispatch } from "react-redux";
import { updateBrandsDetails } from "../../slices/userSlice";

const BrandDetails = ({
  setManage,
  manage,
  setLoading,
  setDropdown,
  dropdown,
  isProject,
  setIsProject,
  projectButtonVisible,
  setprojectButtonVisible,
  setDetailsLoading
}) => {
  const dispatch = useDispatch();
  const [importProject, setImportProject] = useState(false);
  const [brandTab, setBrandTab] = useState("PROJECTS");
  const navigate = useNavigate();
  const pathname = useParams();
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  const [query, setQuery] = useState("");
  const { id } = pathname;
  const [step, setStep] = useState(1);
  const [projectList, setProjectList] = useState([]);
  const [searchedProjectList, setSearchedProjectList] = useState([]);
  const { id: brandId } = useParams();
  const currentUrl = window.location.href;

  const projectTemplateIds = currentUrl.split("/");
  useEffect(() => {
    if (projectTemplateIds[7]?.includes("true")) {
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      projectTemplateIds[7] &&
      !projectTemplateIds[7]?.includes("true") &&
      !projectTemplateIds[7]?.includes("isGenerated")
    ) {
      setStep(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectListFnx = async () => {
    let [error, response] = await Utils.parseResponse(
      getProjectList(projectTemplateIds[6])
    );
    if (error || !response) {
      setProjectList([]);
      return;
    }
    setProjectList(response?.responseData);
  };

  useEffect(() => {
    if (brandId) {
      getProjectListFnx();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);
  const getBrandDetail = async () => {
    try {
      setDetailsLoading(true);
      let [error, response] = await Utils.parseResponse(
        getBrandDetails(projectTemplateIds[6])
      );

      if (error || !response) {
        return;
      }
      setIsProject(response?.responseData);
      dispatch(updateBrandsDetails(response?.responseData));
    } catch (e) {
      console.error(e);
    } finally {
      setDetailsLoading(false);
    }
  };
  const getComponents = () => {
    return brandTab === "EDIT_BRAND" ? (
      <UserBrandDetails
        brandData={isProject}
        setBrandTab={setBrandTab}
        getBrandDetail={getBrandDetail}
      />
    ) : (
      <Project
        data={isProject}
        brandDetails={isProject}
        setprojectButtonVisible={setprojectButtonVisible}
        projectButtonVisible={projectButtonVisible}
        setStep={setStep}
        step={step}
        projectList={projectList}
        getProjectListFnx={getProjectListFnx}
        query={query}
        searchedProjectList={searchedProjectList}
        setImportProject={setImportProject}
        importProject={importProject}
      />
    );
  };

  useEffect(() => {
    getBrandDetail();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="w-full">
      <div
        style={{
          background:
            "linear-gradient(180deg, #021327 0%, #04264E 0.01%, #000000 100%)",
          marginLeft: 32,
          marginRight: 32,
          marginTop: 20,
          borderRadius: 16,
          height: 150
        }}
      >
        <Header
          user={user}
          navigate={navigate}
          isProject={isProject}
          setNavigate={(item) =>
            navigate(`/dashboard/brand/details/${item?.id}`)
          }
          dropdown={dropdown}
          setDropdown={setDropdown}
          manage={manage}
          setManage={setManage}
          pathname={pathname}
          query={query}
          setQuery={setQuery}
          setLoading={setLoading}
          isProjectSearch={true}
          setSearchedProjectList={setSearchedProjectList}
          setImportProject={setImportProject}
          importProject={importProject}
          setStep={setStep}
          setBrandTab={setBrandTab}
          step={step}
        />
      </div>

      <div className={` relative px-8 py-2 pt-6`}>
        <div
          className={`h-full ${brandTab === "EDIT_BRAND" ? " w-full 2xl:w-[87%]" : "w-full"}  mt-19 overflow-auto rounded-2xl ${step === 1 ? "bg-white p-10 pl-16.2" : ""} `}
        >
          {getComponents()}
        </div>

        <div className=" absolute -top-[48px] z-20 w-[95.5%] px-8">
          <div
            style={{
              background:
                "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
              height: 116,
              width: "100%",
              borderRadius: 16,
              boxShadow: "0px 2px 5.5px 0px #00000005",
              padding: "20px 54px 20px 32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backdropFilter: "blur(8px)"
            }}
          >
            <div className="flex items-center">
              <div className={`size-81px`}>
                <>
                  {isProject && isProject?.theme?.logo != " " ? (
                    <img
                      src={isProject?.theme?.logo}
                      alt="logo"
                      className="h-[80px] w-81px rounded-xl"
                    />
                  ) : (
                    <div className="flex size-full items-center justify-center rounded-xl bg-green-12 font-InterBold text-ft36-40 text-white">
                      {isProject?.brandName?.charAt(0)?.toUpperCase()}
                    </div>
                  )}
                </>
              </div>

              <div className="ml-7 flex h-auto flex-col">
                <span
                  className={` font-InterBold text-ft24-32 ${isProject ? "text-black-9" : "text-grey-16"}`}
                >
                  {isProject?.brandName && (
                    <Fragment>
                      <div className="w-52 truncate">
                        <span>{isProject?.brandName}</span>
                      </div>
                    </Fragment>
                  )}
                </span>
                <span className=" mb-2 mt-1 font-InterRegular text-ft14-19.6 text-grey-22">
                  {isProject?.businessType && (
                    <Fragment>
                      {isProject?.businessType || "No email found"}
                    </Fragment>
                  )}
                </span>
              </div>
            </div>

            {isProject?.brandName && !projectButtonVisible && step != 4 && (
              <div className=" flex justify-end">
                <div
                  className={`flex items-center ${pathname.id === "user-details" && "gap-x-4"}`}
                >
                  <div
                    className={`h-8.7 w-[137.43px] cursor-pointer rounded-2xl ${brandTab === "PROJECTS" && "bg-white shadow-dark"} flex items-center justify-center font-InterSemibold text-ft14-19.6 text-black-0`}
                    onClick={() => setBrandTab("PROJECTS")}
                  >
                    PROJECTS
                  </div>
                  <div
                    className={`h-8.7 w-[137.43px] cursor-pointer rounded-2xl ${brandTab === "EDIT_BRAND" && "bg-white shadow-dark"} flex items-center justify-center font-InterSemibold text-ft14-19.6 text-black-0`}
                    onClick={() => setBrandTab("EDIT_BRAND")}
                  >
                    EDIT BRAND
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDetails;
