/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Utils from "../../../utility";
import {
  uploadFile,
  uploadImageByUrl,
  getSearchImagesAI
} from "../../../services/userService";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../slices/userSlice";
import InfiniteScroll from "react-infinite-scroll-component";

const Modal = ({
  heading,
  content,
  buttonText,
  cancelText,
  closeModal,
  functionCall,
  isUpload = false,
  isImages = false,
  buttonClassName,
  cancelTextClassName,
  contentClassName,
  headingClassName,
  popupClassName,
  onSelect,
  searchQuery
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ image: "" });
  const [images, setImages] = useState([]);
  const [searchedImages, setSearchedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(0);
  // const loader = useRef(null);
  const [prevImageUrl, setPrevImageUrl] = useState(""); // State to track the previous image URL
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageFile, setImageFile] = useState();
  // eslint-disable-next-line no-unused-vars
  const [searchKey, setSearchkey] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const uploadImage = async (file) => {
    dispatch(showLoader());
    let [error, response] = await Utils.parseResponse(uploadFile(file));
    if (error || !response) {
      dispatch(hideLoader());
      return;
    }
    dispatch(hideLoader());
    return response?.responseData;
  };
  const handleChange = (e) => {
    if (e.target.type === "file") {
      const file = e.target.files[0];
      dispatch(showLoader());
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const imageURL = URL.createObjectURL(file);
        setImageFile(file);
        setInputs((prev) => ({ ...prev, image: imageURL }));
        setImageUploaded(true);
      } else {
        toast.error("Image type should be jpg or png.");
      }
      dispatch(hideLoader());
    }
  };

  const handleImageUpload = async () => {
    if (!imageFile) {
      toast.error("Please select an image.");
      return;
    }
    try {
      dispatch(showLoader());
      const URL = await uploadImage(imageFile);
      if (URL && URL.startsWith("https")) {
        setInputs((prev) => ({ ...prev, image: URL }));
        onSelect(URL);
        setImageUploaded(true);
        toast.success("Image uploaded successfully.");
      } else {
        toast.error("Image upload failed.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("An error occurred while uploading the image.");
    }

    dispatch(hideLoader());
  };
  const uploadImageFromUrl = async (imageUrl) => {
    if (imageUrl === prevImageUrl || uploadInProgress) return; // Prevent duplicate upload calls for the same URL or if an upload is in progress
    dispatch(showLoader());
    setUploadInProgress(true); // Set upload in progress
    try {
      const response = await uploadImageByUrl(imageUrl);
      const imageURL = response.responseData;
      if (imageURL && imageURL.startsWith("https")) {
        setInputs((prev) => ({ ...prev, image: imageURL }));
        if (prevImageUrl) {
          toast.success("New image uploaded successfully."); // New toast for new image upload
        } else {
          toast.success("Image uploaded successfully.");
        }
        onSelect(imageURL);
        setPrevImageUrl(imageURL); // Update the previous image URL
        setImageUploaded(true); // Set image uploaded to true
      } else {
        toast.error("Failed to upload image from URL.");
      }
    } catch (error) {
      toast.error("Failed to upload image from URL.");
    }
    dispatch(hideLoader());
    setUploadInProgress(false); // Reset upload in progress
  };
  const fetchImages = async () => {
    const limit = 30;
    // When there's a search query, start from page 1, otherwise continue with the next page
    const nextPage = searchQuery ? 1 : page + 1;
    if (!searchQuery) {
      setSearchedImages([]);
    }

    try {
      setLoading(true);
      const response = await getSearchImagesAI(nextPage, limit, searchQuery);

      // Ensure imageData is an object with results
      let imageData = response.responseData || { results: [] };

      // Ensure results is an array
      const results = searchQuery ? imageData.results : imageData;

      // Map the results to get image URLs
      const imageUrls = results.map((image) => image.urls.regular);

      // Handling state based on whether there's a search query or not
      if (searchQuery) {
        // If there's a search query, replace the images state with the search results
        setSearchedImages(imageUrls);
        setPage(0); // Reset page to 0 since we're displaying search results
        setHasMore(results.length >= limit); // Set hasMore based on search results length
      } else {
        // If there's no search query, append new results to the existing state
        setImages((prevImages) => [...prevImages, ...imageUrls]);
        setPage(nextPage); // Increment page for paginated loading
        setHasMore(results.length >= limit); // Set hasMore based on pagination results length
      }
    } catch (error) {
      toast.error("Failed to fetch images.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const defaultButtonClasses = `text-white ${buttonText.includes("DELETE") ? "bg-red-11" : "bg-green-12"} my-4 flex h-8.7 w-51.7 cursor-pointer items-center justify-center rounded-3xl px-2 font-InterBold text-ft14-19.6`;
  const defaultCancelClassTextName = `my-4 flex h-8.7 w-51.7 cursor-pointer items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent px-2 font-InterBold text-ft14-19.6 text-grey-14`;
  const defaultContentClassName = `mt-5 w-70per text-center font-InterRegular text-ft14-20 text-grey-14`;
  const defaultHeadingClassName = `font-InterBold text-ft32-41.6 text-grey-24`;

  return (
    <div className="fixed right-0 top-0 z-100 flex size-full items-center justify-center bg-blurDark backdrop-blur-sm">
      <div
        className={`relative ${
          isImages
            ? "mt-9 h-[923px] min-h-[923px] w-[1314px]"
            : popupClassName
              ? "min-h-[234px]"
              : "min-h-[278px] w-166.2"
        } rounded-2xl bg-white shadow-14xl ${
          isUpload && "pt-16"
        } flex flex-col items-center justify-center`}
      >
        <img
          src="/images/close-icon.svg"
          alt="Close"
          onClick={() => {
            localStorage.removeItem("isFirstTimeUser");
            closeModal();
          }}
          className="absolute right-4 top-4 cursor-pointer"
        />
        <span className={`${headingClassName || defaultHeadingClassName}`}>
          {heading}
        </span>
        <div className={`${contentClassName || defaultContentClassName}`}>
          {content}
        </div>

        {isImages && (
          <div
            id="scroll-bar-unsplash"
            className={` mt-4 max-h-[800px] w-full overflow-y-auto ${loading ? "pr-0" : "p-4"} `}
          >
            <InfiniteScroll
              dataLength={searchQuery ? searchedImages.length : images.length}
              next={fetchImages}
              hasMore={hasMore}
              loader={
                loading && (
                  <div className="flex items-center justify-center">
                    <MoonLoader loading={loading} color="#17C471" />
                  </div>
                )
              }
              className={"overflow-hidden"} // Ensure this doesn't add additional scrolling
              endMessage={""}
              scrollableTarget="scroll-bar-unsplash" // This targets the correct scrollable element
            >
              <div className="grid grid-cols-4 gap-4">
                {(searchQuery ? searchedImages : images).map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      alt={`Unsplash Image ${index}`}
                      className={`cursor-pointer rounded-2xl object-cover ${
                        selectedImage === image
                          ? "border-2 border-[#17C471]"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedImage(image);
                      }}
                      style={{
                        width: "100%",
                        height: "150px"
                      }}
                    />
                    {selectedImage === image && (
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          width: "37px",
                          height: "37px",
                          backgroundColor: "#17C471",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                          style={{ width: "20px", height: "20px" }}
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        )}

        {isUpload && (
          <div className="flex w-full flex-col items-center justify-center">
            <label
              htmlFor="image"
              className="relative mt-34.5px flex h-[406px] w-[540px] cursor-pointer flex-col items-center justify-center rounded-2xl border-2 border-dashed border-[#AEAEAE]"
            >
              <input
                type="file"
                accept="image/png, image/jpeg"
                name="image"
                id="image"
                className="hidden"
                onChange={(e) => handleChange(e)}
              />
              {!inputs.image && loading && (
                <MoonLoader
                  loading={loading}
                  color="#17C471"
                  cssOverride={{
                    position: "absolute",
                    zIndex: 1000,
                    top: "45%",
                    left: "50%"
                  }}
                />
              )}
              {!inputs.image ? (
                <>
                  <img
                    src="/images/upload-own.svg"
                    alt="ico"
                    className="h-[75px] w-[58px]"
                  />
                  <span className="mt-4 font-InterRegular text-ft14-19.6 text-[#A0AEC0]">
                    Allowed file types: png, jpg
                  </span>
                </>
              ) : (
                <img
                  src={inputs.image}
                  alt="uploaded"
                  className="absolute inset-0 m-auto size-full rounded-2xl object-cover"
                />
              )}
            </label>
            <span className="my-4 w-full max-w-80per text-center font-InterRegular text-ft11-15.4 text-[#A0AEC0]">
              *By uploading an image, you agree that you are solely responsible
              for any images you upload and use with AdPerfect, including
              ensuring you have all necessary rights and that the images and
              their completed and derivative works do not infringe any
              intellectual property or contain unlawful content. AdPerfect is
              not liable for any claims or damages arising from your use or
              upload of images.
            </span>
          </div>
        )}

        <div className="flex w-full items-center justify-center gap-x-10 px-24">
          {cancelText && (
            <div
              className={`${cancelTextClassName || defaultCancelClassTextName}`}
              onClick={() => {
                localStorage.removeItem("isFirstTimeUser");
                closeModal();
              }}
            >
              {cancelText}
            </div>
          )}

          {buttonText && (
            <div
              className={`${buttonClassName || defaultButtonClasses}`}
              onClick={async () => {
                if (!uploadInProgress) {
                  localStorage.removeItem("isFirstTimeUser");
                  if (isImages) {
                    if (selectedImage) {
                      await uploadImageFromUrl(selectedImage);
                      closeModal();
                    } else {
                      let toastId;
                      toastId = toast.error("Please select an image.");
                      toast.dismiss(toastId);
                    }
                  } else if (isUpload && inputs.image) {
                    await handleImageUpload();
                    if (imageUploaded) {
                      closeModal();
                    }
                  } else {
                    await functionCall(inputs);
                    closeModal();
                  }
                }
              }}
            >
              {buttonText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
