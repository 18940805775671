import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarItems } from "../../constants";
import { RemainingToken } from "./popup/RemainigToken";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const handleSupportPage = () => {
    window.open(process.env.REACT_APP_AD_PERFECT_CUSTOMER_SUPPORT_URL);
  };
  return (
    <div className="relative mt-3 flex w-15per flex-col">
      <img
        src="/images/adperfect-logo-dark.svg"
        className="ml-20per mt-6per"
        alt="Dark logo"
      />
      <div className=" mt-[28px]">
        {sidebarItems.map((item, index) => {
          return (
            <div
              className={` ml-15per flex h-11 items-center ${currentPath.includes(item.value) && "bg-white shadow-5xl"} m-2 cursor-pointer rounded-xl py-7 pl-4`}
              key={index}
              onClick={() => {
                item.path === "/support"
                  ? handleSupportPage()
                  : navigate(`${item.path}`);
              }}
            >
              <div
                className={` flex size-7.5 items-center justify-start rounded-md p-2 ${currentPath.includes(item.value) ? "mr-2 bg-green-12" : "mr-2 bg-white"}`}
              >
                <img
                  src={
                    currentPath.includes(item.value)
                      ? item.activeIcon
                      : item.inactiveicon
                  }
                  alt="ico"
                  className="size-4"
                />
              </div>
              <div
                className={`font-InterBold text-ft14-20 ${currentPath.includes(item.value) ? "text-grey-12" : "text-grey-14"}`}
              >
                {item.name}
              </div>
            </div>
          );
        })}
        <RemainingToken className="absolute bottom-0 w-full" />
      </div>
    </div>
  );
};

export default Sidebar;
