/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Utils from "../../../utility";
import { getProjectDetalis } from "../../../services/userService";

const AllTemplates = ({
  projectDetailsState,
  projectDetailsGeneratedData,
  brandDetails
}) => {
  const currentUrl = window.location.href;

  const brandAndProjectId = currentUrl.split("/");
  const [projectDetails, setProjectDetalis] = useState({});
  const navigate = useNavigate();

  const getProjectDetails = async (projectId) => {
    try {
      const [error, response] = await Utils.parseResponse(
        getProjectDetalis(projectId)
      );

      if (error || !response) {
        console.error("Error");
        return;
      }

      setProjectDetalis(response?.responseData);
    } catch (err) {
      console.error("An error occurred while fetching project details:", err);
    } finally {
      // DO SOMETHING
    }
  };
  useEffect(() => {
    if (brandAndProjectId[7]) {
      if (brandAndProjectId[7]?.includes("true")) {
        getProjectDetails(brandAndProjectId[8]);
      } else {
        getProjectDetails(brandAndProjectId[7]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function truncateString(str, num) {
    if (str?.length > num) {
      return str?.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const redirectToEditor = (templateId) => {
    navigate(
      `/editor/${templateId}/${projectDetailsGeneratedData?.promptRes?.id ? projectDetailsGeneratedData?.promptRes?.id : projectDetailsGeneratedData?.promptRes?._id ? projectDetailsGeneratedData?.promptRes?._id : brandAndProjectId[7]?.includes("true") ? brandAndProjectId[8] : brandAndProjectId[7]}`
    );
  };

  const headingText =
    (projectDetailsGeneratedData?.promptRes?.generatedData?.headlines?.length >
      0 &&
      projectDetailsGeneratedData?.promptRes?.generatedData?.headlines[0]) ||
    (projectDetails?.generatedData?.headlines?.length > 0 &&
      projectDetails?.generatedData?.headlines[0]);

  const subHeadingText =
    (projectDetailsGeneratedData?.promptRes?.generatedData?.headlines?.length >
      0 &&
      projectDetailsGeneratedData?.promptRes?.generatedData?.headlines[1]) ||
    (projectDetails?.generatedData?.headlines?.length > 0 &&
      projectDetails?.generatedData?.headlines[1]);

  const defaultTemplate = [
    {
      _id: "66f5266f04862dccbe803c19",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) )",
      heading: {
        top: "180px",
        left: "32px",
        width: "80%",
        text: headingText
      },
      subHeading: {
        top: "180px",
        left: "32px",
        width: "80%",
        text: subHeadingText
      },
      button: {
        bottom: "21px",
        left: "24px"
      },
      logo: {
        left: "24px",
        top: "24px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f5274c04862dccbe803c1a",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        top: "190px",
        left: "32px",
        width: "80%",
        text: headingText
      },
      subHeading: {
        top: "190px",
        left: "32px",
        width: "80%",
        text: subHeadingText
      },
      button: {
        bottom: "21px",
        left: "24px"
      },
      logo: {
        left: "24px",
        top: "24px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f5283a04862dccbe803c1b",
      size: "SQUARE",
      fontColor: "text-white",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) )",
      heading: {
        top: "190px",
        left: "32px",
        width: "80%",
        text: headingText
      },
      subHeading: {
        top: "190px",
        left: "32px",
        width: "80%",
        text: subHeadingText
      },
      button: {
        bottom: "21px",
        left: "24px",
        color: "text-white",
        backGroundColor: "bg-blue-4379F2"
      },
      logo: {
        left: "24px",
        top: "24px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f52d8104862dccbe803c1c",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        left: "24px",
        top: "24px",
        width: "80%",
        text: headingText
      },
      subHeading: {
        left: "24px",
        top: "24px",
        width: "80%",
        text: subHeadingText
      },
      button: {
        bottom: "21px",
        left: "24px",
        color: "text-white",
        backGroundColor: "bg-blue-4379F2"
      },
      logo: {
        bottom: "21px",
        right: "40px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f52df304862dccbe803c1d",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        left: "30px",
        top: "140px",
        width: "80%",
        text: headingText
      },
      subHeading: {
        top: "190px",
        left: "32px",
        width: "80%",
        text: subHeadingText
      },
      button: {
        bottom: "21px",
        left: "100px",
        color: "text-white",
        backGroundColor: "bg-blue-4379F2"
      },
      logo: {
        left: "120px",
        top: "24px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63770d504568e56501d85",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        top: "130px",
        left: "32px",
        width: "80%",
        text: headingText
      },
      subHeading: {
        top: "130px",
        left: "32px",
        width: "80%",
        text: subHeadingText
      },
      button: {
        bottom: "21px",
        left: "32px"
      },
      logo: {
        left: "24px",
        top: "24px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f637c9d504568e56501d86",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        top: "130px",
        right: "34px",
        width: "70%",
        text: headingText
      },
      subHeading: {
        top: "130px",
        right: "34px",
        width: "70%",
        text: subHeadingText
      },
      button: {
        bottom: "50px",
        right: "34px"
      },
      logo: {
        right: "34px",
        top: "34px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f6382cd504568e56501d87",
      size: "SQUARE",
      fontColor: "text-black-0",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",

      logo: {
        display: "flex",
        "justify-items": "center",
        height: "100%",
        "align-items": "center",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f6399cd504568e56501d8c",
      size: "VERTICAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      isBlackBackground: true,
      fontColor: "text-white",
      heading: {
        width: "100%",
        text: headingText
      },
      subHeading: {
        width: "100%",

        text: subHeadingText
      },
      blackBackground: {
        display: "flex",
        "align-items": "end",
        "justify-contents": "space-between",
        height: "100%"
      },
      button: {},
      logo: {
        left: "24px",
        top: "24px",

        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63883d504568e56501d88",
      size: "VERTICAL",

      logo: {
        display: "flex",
        "justify-items": "center",
        height: "100%",
        "align-items": "center",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f638d0d504568e56501d89",
      size: "VERTICAL",
      logo: {
        right: "34px",
        top: "34px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63909d504568e56501d8a",
      size: "VERTICAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "padding-left": "34px",
        width: "100%",
        text: headingText
      },
      subHeading: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "padding-left": "34px",
        width: "100%",
        text: subHeadingText
      },
      button: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "padding-left": "34px"
      },
      logo: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "padding-left": "34px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f6395dd504568e56501d8b",
      size: "VERTICAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        display: "flex",
        "justify-content": "start",
        "align-items": "start",
        "padding-left": "34px",
        width: "100%",
        text: headingText
      },
      subHeading: {
        display: "flex",
        "justify-content": "start",
        "align-items": "start",
        "padding-left": "34px",
        width: "100%",
        text: subHeadingText
      },
      button: {
        display: "flex",
        "justify-content": "start",
        "align-items": "start",
        "padding-left": "34px"
      },
      logo: {
        display: "flex",
        "justify-content": "start",
        "align-items": "start",
        "padding-left": "34px",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f639ded504568e56501d8d",
      size: "VERTICAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        display: "flex",
        "justify-content": "end",
        "align-items": "end",
        "padding-right": "34px",
        width: "100%",
        text: headingText
      },
      subHeading: {
        display: "flex",
        "justify-content": "end",
        "align-items": "end",
        "padding-right": "34px",
        width: "100%",
        text: subHeadingText
      },
      button: {
        display: "flex",
        "justify-content": "end",
        "align-items": "end",
        "padding-right": "34px"
      },
      logo: {
        display: "flex",
        "justify-content": "end",
        "align-items": "end",
        "padding-right": "34px",
        text: "LOGO",
        imageUrl: ""
      }
    },

    {
      _id: "66f63a3bd504568e56501d8e",
      size: "HORIZONTAL",
      fontColor: "text-white",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) )",
      heading: {
        textAlign: "center",
        text: headingText,
        width: "100%"
      },
      subHeading: {
        width: "100%",
        textAlign: "center",
        text: subHeadingText
      },
      textBtnDiv: {
        display: "flex",
        "align-items": "end",
        "justify-contents": "space-between",
        height: "100%"
      },
      textBtnChildDiv: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "column",
        "justify-contents": "space-between",
        gap: "40px"
      },
      button: {},
      logo: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63a7fd504568e56501d8f",
      size: "HORIZONTAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      heading: {
        textAlign: "center",
        text: headingText,
        width: "100%"
      },
      subHeading: {
        width: "100%",
        textAlign: "center",
        text: subHeadingText
      },
      textBtnDiv: {
        display: "flex",
        "align-items": "end",
        "justify-contents": "space-between",
        height: "100%"
      },
      textBtnChildDiv: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "column",
        "justify-contents": "space-between",
        gap: "40px"
      },
      button: { color: "text-white", backGroundColor: "bg-blue-4379F2" },
      logo: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63acbd504568e56501d90",
      size: "HORIZONTAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      mainDiv: {
        "justify-content": "center",
        gap: "20px",
        padding: "0 20px 0 20px"
      },
      heading: {
        textAlign: "start",
        text: headingText,
        width: "100%"
      },
      subHeading: {
        width: "100%",
        textAlign: "start",
        text: subHeadingText
      },
      textBtnDiv: {
        display: "flex",
        "align-items": "end",
        "justify-contents": "start"
      },
      textBtnChildDiv: {
        display: "flex",
        "align-items": "start",
        "flex-direction": "column",
        "justify-contents": "space-between",
        gap: "20px"
      },
      button: {},
      logo: {
        display: "flex",
        "justify-contents": "start",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63b0bd504568e56501d91",
      size: "HORIZONTAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) )",
      fontColor: "text-white",
      heading: {
        textAlign: "center",
        text: headingText,
        width: "100%"
      },
      subHeading: {
        width: "100%",
        textAlign: "center",
        text: subHeadingText
      },
      textBtnDiv: {
        display: "flex",
        "align-items": "end",
        "justify-contents": "space-between",
        height: "100%"
      },
      textBtnChildDiv: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "column",
        "justify-contents": "space-between",
        gap: "40px"
      },
      logo: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f63b70d504568e56501d92",
      size: "HORIZONTAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      mainDiv: {
        padding: "24px 20px 40px 20px"
      },
      heading: {
        textAlign: "center",
        text: headingText,
        width: "100%"
      },
      subHeading: {
        width: "100%",
        textAlign: "center",
        text: subHeadingText
      },
      textBtnDiv: {
        display: "flex",
        "align-items": "end",
        "justify-contents": "space-between",
        height: "100%"
      },
      textBtnChildDiv: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "column",
        "justify-contents": "space-between",
        gap: "40px"
      },
      button: {},
      logo: {
        display: "flex",
        "justify-content": "start",
        "align-items": "center",
        text: "LOGO",
        imageUrl: ""
      }
    },
    {
      _id: "66f5418404862dccbe803c1e",
      size: "HORIZONTAL",
      linearGradient:
        "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )",
      mainDiv: {
        "justify-content": "space-between",
        gap: "20px",
        padding: "20px 20px 40px 20px"
      },
      heading: {
        textAlign: "start",
        text: headingText,
        width: "100%"
      },
      subHeading: {
        width: "100%",
        textAlign: "start",
        text: subHeadingText
      },
      textBtnDiv: {
        display: "flex",
        "align-items": "center",
        height: "60%"
      },
      textBtnChildDiv: {
        display: "flex",
        "align-items": "start",
        "flex-direction": "column",
        "justify-content": "space-between",
        height: "100%",
        gap: "20px"
      },
      button: {},
      logo: {
        display: "flex",
        "justify-contents": "start",
        text: "LOGO",
        imageUrl: ""
      }
    }
  ];

  const squareTemplate = (squareTemplateDetails) => {
    return (
      <div
        style={{
          backgroundImage: `${squareTemplateDetails?.linearGradient},url(${Utils.fixUrl(
            projectDetailsState?.selectedImage ||
              projectDetails?.createdImage ||
              projectDetails?.selectedImage ||
              "/images/white-background.jpg"
          )})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
        className="relative h-305px rounded-xl"
      >
        <div
          onClick={() => redirectToEditor(squareTemplateDetails?._id)}
          className="absolute -right-3.5 -top-2 flex size-12 cursor-pointer items-center justify-center rounded-xl bg-white shadow-pencilBoxShadow"
        >
          <img src="/images/edit-icon.svg" />
        </div>

        <div
          style={{ ...squareTemplateDetails?.logo }}
          className={`${squareTemplateDetails?.logo?.display ? squareTemplateDetails?.logo?.display : "absolute"}  flex  items-center justify-center  font-InterBold text-ft12-16 text-white`}
        >
          {brandDetails?.theme?.logo ? (
            <img className="size-15" src={brandDetails?.theme?.logo} />
          ) : (
            squareTemplateDetails.logo.text
          )}
        </div>
        {squareTemplateDetails?.heading && (
          <div
            style={{ ...squareTemplateDetails?.heading }}
            className={`absolute font-InterSemibold text-ft21-29.4 ${squareTemplateDetails?.fontColor}`}
          >
            {squareTemplateDetails?.heading?.text ||
              truncateString(projectDetails?.projectDescription, 50) ||
              truncateString(projectDetailsState?.projectDescription, 50)}
          </div>
        )}
        {squareTemplateDetails?.button && (
          <button
            style={{ ...squareTemplateDetails?.button }}
            className={`absolute flex h-6 w-111px items-center justify-center ${squareTemplateDetails?.button?.backGroundColor ? squareTemplateDetails?.button?.backGroundColor : "bg-white"}  font-InterMedium text-ft11-15.4 ${squareTemplateDetails?.button?.color ? squareTemplateDetails?.button?.color : "text-black-0 "} shadow-buttonShadow`}
          >
            LEARN MORE
          </button>
        )}
      </div>
    );
  };

  const verticalTemplate = (verticalTemplateDetails) => {
    return (
      <div
        style={{
          backgroundImage: `url(${Utils.fixUrl(
            projectDetailsState?.selectedImage ||
              projectDetails?.createdImage ||
              projectDetails?.selectedImage ||
              "/images/white-background.jpg"
          )})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
        className={`relative flex h-305px flex-col justify-between rounded-xl ${!verticalTemplateDetails?.isBlackBackground && "py-6"} `}
      >
        <div
          onClick={() => redirectToEditor(verticalTemplateDetails?._id)}
          className="absolute -right-3.5 -top-2 flex size-12 cursor-pointer items-center justify-center rounded-xl bg-white shadow-pencilBoxShadow"
        >
          <img src="/images/edit-icon.svg" />
        </div>
        <div
          style={{ ...verticalTemplateDetails?.logo }}
          className={`${verticalTemplateDetails?.logo?.display ? verticalTemplateDetails?.logo?.display : "absolute"} flex  items-center justify-center  font-InterBold text-ft12-16 text-white`}
        >
          {brandDetails?.theme?.logo ? (
            <img className="size-15" src={brandDetails?.theme?.logo} />
          ) : (
            verticalTemplateDetails?.logo?.text
          )}
        </div>
        {verticalTemplateDetails?.isBlackBackground ? (
          <div style={{ ...verticalTemplateDetails?.blackBackground }}>
            <div className="flex h-24 w-full items-center justify-between rounded-b-xl bg-black-0 bg-opacity-50 px-10">
              {verticalTemplateDetails?.heading && (
                <div
                  style={{ ...verticalTemplateDetails?.heading }}
                  className={`${verticalTemplateDetails?.heading?.display ? verticalTemplateDetails?.heading?.display : ""} `}
                >
                  <div
                    className={`font-InterSemibold text-ft30-36 ${verticalTemplateDetails?.fontColor || ""}`}
                  >
                    <span className="w-60per">
                      {verticalTemplateDetails?.heading?.text ||
                        truncateString(
                          projectDetails?.projectDescription,
                          50
                        ) ||
                        truncateString(
                          projectDetailsState?.projectDescription,
                          50
                        )}
                    </span>
                  </div>
                </div>
              )}
              {verticalTemplateDetails?.button && (
                <div style={{ ...verticalTemplateDetails?.button }}>
                  <button
                    className={`${verticalTemplateDetails?.button?.display ? verticalTemplateDetails?.button?.display : ""} flex h-12 w-130.5px items-center justify-center ${verticalTemplateDetails?.button?.backGroundColor ? verticalTemplateDetails?.button?.backGroundColor : "bg-white"}  font-InterMedium text-ft15-22.5 ${verticalTemplateDetails?.button?.color ? verticalTemplateDetails?.button?.color : "text-black-0 "} shadow-buttonShadow`}
                  >
                    LEARN MORE
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {verticalTemplateDetails?.heading && (
              <div
                style={{ ...verticalTemplateDetails?.heading }}
                className={`${verticalTemplateDetails?.heading?.display ? verticalTemplateDetails?.heading?.display : "absolute"} `}
              >
                <div
                  className={`font-InterSemibold text-ft30-36 ${verticalTemplateDetails?.fontColor || ""}`}
                >
                  <span className="w-60per">
                    {verticalTemplateDetails?.heading?.text ||
                      truncateString(projectDetails?.projectDescription, 50) ||
                      truncateString(
                        projectDetailsState?.projectDescription,
                        50
                      )}
                  </span>
                </div>
              </div>
            )}
            {verticalTemplateDetails?.button && (
              <div style={{ ...verticalTemplateDetails?.button }}>
                <button
                  className={`${verticalTemplateDetails?.button?.display ? verticalTemplateDetails?.button?.display : "absolute"} flex h-12 w-130.5px items-center justify-center ${verticalTemplateDetails?.button?.backGroundColor ? verticalTemplateDetails?.button?.backGroundColor : "bg-white"}  font-InterMedium text-ft15-22.5 ${verticalTemplateDetails?.button?.color ? verticalTemplateDetails?.button?.color : "text-black-0 "} shadow-buttonShadow`}
                >
                  LEARN MORE
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const horizontalTemplate = (horizontalTemplateDetails) => {
    return (
      <div
        style={{
          backgroundImage: `${horizontalTemplateDetails?.linearGradient},url(${Utils.fixUrl(
            projectDetailsState?.selectedImage ||
              projectDetails?.createdImage ||
              projectDetails?.selectedImage ||
              "/images/white-background.jpg"
          )})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          ...horizontalTemplateDetails.mainDiv
        }}
        className="relative flex h-[634px] flex-col rounded-xl py-6"
      >
        <div
          onClick={() => redirectToEditor(horizontalTemplateDetails?._id)}
          className="absolute -right-3.5 -top-2 flex size-12 cursor-pointer items-center justify-center rounded-xl bg-white shadow-pencilBoxShadow"
        >
          <img src="/images/edit-icon.svg" />
        </div>
        <div
          style={{ ...horizontalTemplateDetails?.logo }}
          className={`${horizontalTemplateDetails?.logo?.display ? horizontalTemplateDetails?.logo?.display : "absolute"}  font-InterBold text-ft12-16 text-white`}
        >
          {brandDetails?.theme?.logo ? (
            <img className="size-15" src={brandDetails?.theme?.logo} />
          ) : (
            horizontalTemplateDetails?.logo?.text
          )}
        </div>
        <div style={{ ...horizontalTemplateDetails?.textBtnDiv }}>
          <div style={{ ...horizontalTemplateDetails?.textBtnChildDiv }}>
            {horizontalTemplateDetails?.heading && (
              <div
                style={{ ...horizontalTemplateDetails?.heading }}
                className={`${horizontalTemplateDetails?.heading?.display ? horizontalTemplateDetails?.heading?.display : ""} `}
              >
                <div
                  className={`font-InterSemibold text-ft30-36 ${horizontalTemplateDetails?.fontColor || ""}`}
                >
                  <span className="w-60per">
                    {horizontalTemplateDetails?.heading?.text ||
                      truncateString(projectDetails?.projectDescription, 50) ||
                      truncateString(
                        projectDetailsState?.projectDescription,
                        50
                      )}
                  </span>
                </div>
              </div>
            )}
            {horizontalTemplateDetails?.button && (
              <div style={{ ...horizontalTemplateDetails?.button }}>
                <button
                  className={`${horizontalTemplateDetails?.button?.display ? horizontalTemplateDetails?.button?.display : ""} flex h-12 w-130.5px items-center justify-center ${horizontalTemplateDetails?.button?.backGroundColor ? horizontalTemplateDetails?.button?.backGroundColor : "bg-white"}  font-InterBold text-ft15-22.5 ${horizontalTemplateDetails?.button?.color ? horizontalTemplateDetails?.button?.color : "text-black-0 "} shadow-buttonShadow`}
                >
                  LEARN MORE
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderTemplate = (template) => {
    switch (template.size) {
      case "SQUARE":
        return squareTemplate(template);
      case "HORIZONTAL":
        return horizontalTemplate(template);
      case "VERTICAL":
        return verticalTemplate(template);
      default:
        return null;
    }
  };

  const squareTemplates = defaultTemplate.filter((t) => t.size === "SQUARE");
  const horizontalTemplates = defaultTemplate.filter(
    (t) => t.size === "HORIZONTAL"
  );
  const verticalTemplates = defaultTemplate.filter(
    (t) => t.size === "VERTICAL"
  );

  return (
    <div className="h-auto w-full rounded-3xl bg-white px-11 pb-10 pt-19">
      <h1 className="font-InterBold text-ft24-32 text-grey-12">
        Generated Ads
      </h1>
      <p className="mt-1.5 font-InterRegular text-ft16-19.36 font-normal text-grey-14">
        You can edit any of the ads by clicking on the pencil icon or select and
        save/download your favorites.
      </p>

      <div className="mt-5 grid grid-cols-2 gap-6">
        <div className=" grid gap-6">
          <div className="grid grid-cols-2 gap-6">
            {squareTemplate(squareTemplates[0])}
            {squareTemplate(squareTemplates[1])}
          </div>
          {verticalTemplate(verticalTemplates[0])}
        </div>
        <div className=" grid grid-cols-2 gap-6">
          <div className=" grid grid-cols-1 gap-6">
            {squareTemplate(squareTemplates[2])}
            {squareTemplate(squareTemplates[3])}
          </div>
          <div>{horizontalTemplate(horizontalTemplates[0])}</div>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-2 gap-6">
        <div className=" grid gap-6">
          <div className="grid grid-cols-2 gap-6">
            {squareTemplate(squareTemplates[4])}
            {squareTemplate(squareTemplates[5])}
          </div>
          {verticalTemplate(verticalTemplates[1])}
        </div>
        <div className=" grid grid-cols-2 gap-6">
          <div className=" grid grid-cols-1 gap-6">
            {squareTemplate(squareTemplates[6])}
            {squareTemplate(squareTemplates[7])}
          </div>
          <div>{horizontalTemplate(horizontalTemplates[1])}</div>
        </div>
      </div>
      <div className="mt-5  gap-6">
        <div className="grid gap-6">
          <div className="grid grid-cols-2 gap-6">
            {verticalTemplates?.slice(2).map((template, index) => (
              <div key={index}>{renderTemplate(template)}</div>
            ))}
          </div>

          <div className="grid grid-cols-4 gap-6">
            {horizontalTemplates?.slice(2).map((template, index) => (
              <div className="h-full" key={index}>
                {renderTemplate(template)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTemplates;
