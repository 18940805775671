/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react";
import Utils from "../../utility";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { MoonLoader } from "react-spinners";

// eslint-disable-next-line no-unused-vars
const notifications = [
  { title: "Notification Number 1", time: "Today - 5:25 pm" },
  { title: "Notification Number 2", time: "Today - 5:30 pm" },
  { title: "Notification Number 3", time: "Yesterday - 5:35 pm" },
  { title: "Notification Number 4", time: "Yesterday- 5:40 pm" },
  { title: "Notification Number 5", time: "Tommorow - 5:45 pm" },
  { title: "Notification Number 6", time: "Tommorow - 5:50 pm" }
];

const Notification = ({
  handleNotificationPopup,
  notificationList,
  fetchUserNotificationList,
  hasMore,
  loadingNotification
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div
      className={` absolute left-auto right-41 top-22.5 z-100 h-73 w-109 rounded-15px border border-grey-#E6E6E6 bg-white py-3 pl-6 pr-3 shadow-13xl`}
    >
      <div className="custom-scrollbar-container">
        <div id="scrollableDiv" className="h-65  overflow-y-auto py-3 pr-2">
          {/* <InfiniteScroll
            dataLength={notificationList.length}
            next={fetchUserNotificationList}
            hasMore={hasMore}
            loader={
              loadingNotification && (
                <div className="flex items-center justify-center py-2">
                  <MoonLoader loading={loadingNotification} color="#17C471" />
                </div>
              )
            }
            scrollableTarget="scrollableDiv"
            className="hide-scrollbar"
          > */}
          {notificationList?.length > 0 ? (
            notificationList.map((notification, index) => (
              <>
                <div
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={handleNotificationPopup}
                  className={`cursor-pointer p-3 ${hoveredIndex === index ? "rounded-7px border border-green-12" : ""}`}
                >
                  <p
                    className={`font-InterSemibold text-ft14-19.6 ${hoveredIndex === index ? "text-green-12" : " text-black-0A090B"}`}
                  >
                    {notification?.title}
                  </p>
                  <p
                    className={`mt-2.7 font-InterRegular ${hoveredIndex === index ? "text-green-12" : " text-darkGrey-20"} text-ft14-19.6 `}
                  >
                    {Utils.formatTimeHavingTimeDateDay(notification?.createdAt)}
                  </p>
                </div>
                <hr className="my-3 border-grey-#F1F1F1"></hr>
              </>
            ))
          ) : (
            <div className=" mt-24 flex items-center justify-center font-InterSemibold text-ft20-28 text-black-0">
              No Notification
            </div>
          )}
          {/* </InfiniteScroll> */}
        </div>
      </div>
    </div>
  );
};

export default Notification;
