import React from "react";

function TextArea({
  label,
  onChange,
  value,
  name,
  height,
  width,
  haveStar = false
}) {
  const handleFocus = (inputId) => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <div className="relative">
      <textarea
        rows={4}
        cols={50}
        type="text"
        id={name}
        name={name || "name"}
        required
        className={`peer block resize-none ${height} ${width} appearance-none  rounded-15px border border-grey-9 px-2.5 pb-2.5 pl-6 pt-4 text-ft16-19.36 text-grey-12 focus:border-green-12 focus:outline-none focus:ring-0 dark:bg-transparent`}
        placeholder=" "
        onChange={onChange}
        value={value || ""}
        maxLength={
          name === "description" || name === "projectDescription" ? 1000 : ""
        }
        minLength={
          (name === "projectDescription" || name === "description") && 10
        }
      />
      <label
        htmlFor={name}
        className={`
absolute -top-2.5 left-3.2 flex items-center bg-white px-1.2   font-InterRegular text-grey-14  peer-focus:text-green-12 text-ft14-20
      `}
        onClick={() => handleFocus(name)}
      >
        {label} {haveStar && <p className="w-max text-red-10">*</p>}
      </label>
    </div>
  );
}

export default TextArea;
