/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { uploadType } from "../../../constants";
import FloatingLabel from "../../../common/components/inputs/FloatingLabel";
import Modal from "../../../common/components/popup/modal";
import ClickOutside from "../../../common/components/clickOutside";
import TextArea from "../../../common/components/textArea";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import AiGeneratedPopup from "./aiGeneratedImages";
import {
  generateAdDetails,
  generatePrompt,
  regenerateAiImages
} from "../../../services/userService";
import Utils from "../../../utility";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  updateSubscriptionDetails
} from "../../../slices/userSlice";

const minAge = Array(64 - 18 + 1)
  .fill()
  .map((_, i) => i + 18);
const maxAge = Array(65 - 19 + 1)
  .fill()
  .map((_, i) => {
    const age = i + 19;
    return age === 65 ? `${age}+` : age;
  });
export default function ProjectDetails({
  setStep,
  setProjectDetailState,
  projectDetailsState,
  projectTypeState,
  brandDetails,
  setProjectDetailsGeneratedData,
  projectDetailsGeneratedData,
  // eslint-disable-next-line no-unused-vars
  projectList
}) {
  const dispatch = useDispatch();
  const currentUrl = window.location.href;

  const projectTemplateIds = currentUrl.split("/");
  const handleProjectNameChange = (e) =>
    setProjectDetailState((state) => ({
      ...state,
      projectName: e.target.value
    }));
  const handleInterestsChange = (e) =>
    setProjectDetailState((state) => ({ ...state, interest: e.target.value }));
  const handleProjectDescriptionChange = (e) =>
    setProjectDetailState((state) => ({
      ...state,
      projectDescription: e.target.value
    }));

  const [query, setQuery] = useState();
  const [searchQuery] = useDebounce(query, 1000);
  const [currentPopup, setCurrentPopup] = useState();

  const [minAgeDropDown, setMinAgeDropDown] = useState(false);
  const [maxAgeDropDown, setMaxAgeDropDown] = useState(false);

  const [showInfoSolution, setShowInfoSolution] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { id: brandId } = useParams();
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setProjectDetailState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const regenerateImages = async () => {
    try {
      dispatch(showLoader());
      const [error, response] = await Utils.parseResponse(
        regenerateAiImages(
          projectDetailsGeneratedData?.promptRes?._id || projectTemplateIds[8]
        )
      );
      if (error) {
        if (error?.response?.data?.message) {
          Utils.failureToastMessage(error?.response?.data?.message);
          return;
        } else {
          Utils.failureToastMessage("Some error occured");
          return;
        }
      } else {
        if (response?.responseData) {
          setProjectDetailsGeneratedData(response?.responseData);
          dispatch(
            updateSubscriptionDetails(
              response?.responseData?.tokenResult?.responseData
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (projectTemplateIds[7]?.includes("true")) {
      setCurrentPopup("aiGeneratedImage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openPopup = () => {
    switch (currentPopup) {
      case "ownImage":
        return (
          <Modal
            heading={"Upload Image"}
            content={"Upload your own image to use for your ads.*"}
            buttonText={"UPLOAD IMAGE"}
            closeModal={() => setCurrentPopup("")}
            isFullScreen={true}
            isUpload={true}
            onSelect={(url) => {
              setProjectDetailState((prev) => ({
                ...prev,
                imageType: "ownImage",
                selectedImage: url
              }));
            }}
            buttonClassName="my-4 flex size-auto cursor-pointer items-center justify-center rounded-2xl bg-green-12 w-87.5 h-[45px] flex items-align font-InterBold text-ft14-21 text-white"
            contentClassName="mt-2.5 w-70per text-center font-InterRegular text-ft14-20 text-grey-14"
          />
        );

      case "royaltyFreeImage":
        return (
          <Modal
            searchQuery={searchQuery}
            heading={"Image Library"}
            content={
              <>
                <p>
                  You can choose from any of the royalty-free images below to
                  use for your ad
                </p>
                <div
                  className={`relative m-auto mt-2 flex h-10.2  w-[341px] cursor-pointer items-center justify-center rounded-3xl border-0.5px bg-white p-2`}
                >
                  {/* ${false ? "border-green-10" : "border-grey-9"} */}
                  <input
                    className="h-8.5 w-[341px] rounded-3xl border-none p-2 outline-none"
                    // className=" flex justify-center m-auto w-[341px] h-[41px] rounded-3xl border-none pl-2 outline-none placeholder:text-ft14-19.6 "
                    placeholder="Search Images"
                    // value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                  {/* <img
                    src="/images/search-icon.svg"
                    className={`  size-6 cursor-pointer`}
                  /> */}
                </div>
              </>
            }
            buttonText={"SELECT IMAGE"}
            closeModal={() => {
              setCurrentPopup("");
              setQuery("");
            }}
            onSelect={(url) => {
              setProjectDetailState((prev) => ({
                ...prev,
                imageType: "royaltyFreeImage",
                selectedImage: url
              }));
            }}
            isImages={true}
            isFullScreen={true}
            buttonClassName="mb-10 cursor-pointer items-center justify-center rounded-2xl bg-green-12 px-30.7  py-3 font-InterBold text-ft14-21 text-white absolute bottom-0 left-[50%] translate-x-[-50%]"
            // buttonClassName="my-4 flex size-auto cursor-pointer items-center justify-center rounded-2xl bg-green-12 px-30.7  py-3 font-InterBold text-ft14-21 text-white"
            contentClassName="mt-2.5 w-70per text-center font-InterRegular text-ft14-20 text-grey-26"
            headingClassName="mt-27px font-InterBold text-ft32-41.6 text-grey-24"
          />
        );
      case "aiGeneratedImage":
        return (
          <AiGeneratedPopup
            setStep={setStep}
            setCurrentPopup={setCurrentPopup}
            setProjectDetailState={setProjectDetailState}
            projectDetailsState={projectDetailsState}
            projectDetailsGeneratedData={projectDetailsGeneratedData}
            regenerateImages={regenerateImages}
          />
        );

      default:
        return null;
    }
  };
  const generateProjectDetailsByPrompt = async (data) => {
    try {
      dispatch(showLoader());
      const [error, response] = await Utils.parseResponse(generatePrompt(data));
      if (error) {
        if (error?.response?.data?.message) {
          Utils.failureToastMessage(error?.response?.data?.message);
          return;
        } else {
          Utils.failureToastMessage("Some error occured");
          return;
        }
      } else {
        Utils.successToastMessage("Project Created");
        if (
          response?.responseData?.promptRes?.platformName === "Google" ||
          response?.responseData?.promptRes?.platformName === "Bing"
        ) {
          dispatch(
            updateSubscriptionDetails(
              response?.responseData?.tokenResult?.responseData
            )
          );
          navigate(
            `/dashboard/brand/details/project/finalReview/${response?.responseData?.promptRes?.id}`
          );
        } else {
          dispatch(
            updateSubscriptionDetails(
              response?.responseData?.tokenResult?.responseData
            )
          );
          setProjectDetailsGeneratedData(response?.responseData);
          setStep(4);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
  const generateProjectDetails = async (data) => {
    try {
      dispatch(showLoader());
      const [error, response] = await Utils.parseResponse(
        generateAdDetails(data)
      );
      if (error) {
        if (error?.response?.data?.message) {
          Utils.failureToastMessage(error?.response?.data?.message);
          return;
        } else {
          Utils.failureToastMessage("Some error occured");
          return;
        }
      } else {
        if (response?.responseData) {
          setProjectDetailsGeneratedData(response?.responseData);
          setCurrentPopup("aiGeneratedImage");
          dispatch(
            updateSubscriptionDetails(
              response?.responseData?.tokenResult?.responseData
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // const doesProjectNameExist = (projectName) => {
  //   const normalizedProjectName = projectName.toLowerCase();
  //   return projectList.some(project => project.projectName.toLowerCase() === normalizedProjectName);
  //   };
  const hanldeCreateProject = async () => {
    // if (doesProjectNameExist(projectDetailsState.projectName)) {
    //   Utils.failureToastMessage('A project with the same name already exists');
    //   return;
    // }
    let projectDetailsData = {
      typeOfAd: projectDetailsState?.adType === "imageAd" ? "image" : "text",
      projectName: projectDetailsState.projectName,
      businessName: brandDetails?.brandName,
      businessModel: brandDetails?.businessModel,
      industry: brandDetails?.businessType,
      brandProblemSolve: brandDetails?.discovery?.problem,
      solutionOffered: brandDetails?.discovery?.solution,
      voiceTone: brandDetails?.discovery?.tone,
      brandMainCompetitor: brandDetails?.discovery?.competitors,
      differentiatingFactor: brandDetails?.values,
      projectType: projectTypeState.projectType,
      platformName: projectTypeState.platformName,
      projectGoal: projectTypeState.goal,
      assetType: projectTypeState?.projectType,
      projectDescription: projectDetailsState.projectDescription,
      targetAudience: {
        gender: projectDetailsState.gender,
        interests: [projectDetailsState.interest],
        minAge: projectDetailsState.minAge,
        maxAge:
          projectDetailsState.maxAge === "65+" ? 65 : projectDetailsState.maxAge
      },

      selectedImage: projectDetailsState?.selectedImage,
      userId: brandDetails?.userId,
      brandId: brandDetails?.id
    };
    if (projectDetailsState.adType === "imageAd") {
      if (
        projectDetailsState.imageType === "ownImage" ||
        projectDetailsState.imageType === "royaltyFreeImage"
      ) {
        localStorage.setItem(
          "projectDetails",
          JSON.stringify(projectDetailsState)
        );
        await generateProjectDetailsByPrompt(projectDetailsData);
      } else if (projectDetailsState.imageType === "aiGeneratedImage") {
        {
          await generateProjectDetails(projectDetailsData);
        }
      }
    } else {
      await generateProjectDetailsByPrompt(projectDetailsData);
    }
  };

  useEffect(() => {
    if (projectDetailsState.imageType === "ownImage") {
      document.body.style.overflow = "hidden";
    }
  }, [projectDetailsState.imageType]);
  useEffect(() => {
    if (
      projectTypeState?.platformName === "Google" ||
      projectTypeState?.platformName === "Bing"
    ) {
      setProjectDetailState((prev) => ({ ...prev, adType: "textAd" }));
    } else {
      setProjectDetailState((prev) => ({ ...prev, adType: "imageAd" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full bg-grey-18">
      <div className="size-full max-w-[997px] rounded-2xl bg-white p-10 pl-16">
        <span className=" font-InterBold text-ft24-32 text-[#A0AEC0]">
          New Project
        </span>
        <div className="flex">
          <div>
            <div className=" mt-6">
              <FloatingLabel
                name={"projectName"}
                label={"Project Name"}
                onBoard={true}
                isStar={true}
                isSmall={true}
                height={"h-11.2"}
                width={"w-108.7"}
                isInfo={false}
                isTool={true}
                value={projectDetailsState.projectName}
                onChange={handleProjectNameChange}
              />
            </div>
            <div className=" mt-6">
              <TextArea
                name={"projectDescription"}
                label={"Project Description"}
                height={"h-25.7"}
                width={"w-108.7"}
                value={projectDetailsState.projectDescription}
                onChange={handleProjectDescriptionChange}
                haveStar={true}
                maxLength={1000}
              />
            </div>
          </div>
          <div className="mx-auto flex max-w-lg flex-col rounded-lg  bg-white px-8 pb-8 pt-6">
            <div className="mb-4 font-InterRegular text-ft14-19.6 text-grey-14">
              Target Audience
            </div>
            <div className="flex h-16.5 items-center gap-9">
              <div className="flex h-16.5 flex-col">
                <label
                  htmlFor="gender"
                  className="mb-2.5 font-InterSemibold text-ft12-16 text-grey-14"
                >
                  Gender
                </label>
                <div className=" flex flex-row gap-5 text-grey-14">
                  <label className="flex items-center font-InterRegular  text-ft12-20">
                    <img
                      onClick={() => handleChange("gender", "other")}
                      className="mr-2 cursor-pointer"
                      alt="other"
                      src={
                        projectDetailsState.gender === "other"
                          ? "/images/enable-radio.svg"
                          : "/images/disabled-radio.svg"
                      }
                    />
                    Both
                  </label>
                  <label className="flex items-center font-InterRegular  text-ft12-20">
                    <img
                      onClick={() => handleChange("gender", "male")}
                      className="mr-2 cursor-pointer"
                      alt="male"
                      src={
                        projectDetailsState.gender === "male"
                          ? "/images/enable-radio.svg"
                          : "/images/disabled-radio.svg"
                      }
                    />
                    Men
                  </label>
                  <label className="flex items-center font-InterRegular text-ft12-20">
                    <img
                      onClick={() => handleChange("gender", "female")}
                      className="mr-2 cursor-pointer"
                      alt="female"
                      src={
                        projectDetailsState.gender === "female"
                          ? "/images/enable-radio.svg"
                          : "/images/disabled-radio.svg"
                      }
                    />
                    Women
                  </label>
                </div>
              </div>
              <div className="flex h-16.5 flex-col">
                <label
                  htmlFor="age"
                  className="mb-1 font-InterSemibold text-ft12-16 text-grey-14"
                >
                  Age
                </label>
                <div className="flex">
                  <div className="relative z-50 w-[69px]">
                    <div
                      onClick={() => {
                        setMinAgeDropDown(!minAgeDropDown);
                      }}
                      className={`flex h-[32px]  w-full cursor-pointer items-center justify-between ${minAgeDropDown ? "" : "rounded-bl-lg"} rounded-tl-lg border border-r-0 border-[#E2E8F0] px-3 py-1.5`}
                    >
                      {projectDetailsState?.minAge}{" "}
                      <img src="/images/down-arrow.svg" alt="" />
                    </div>
                    {minAgeDropDown && (
                      <ClickOutside
                        isOpen={minAgeDropDown}
                        onClickOutside={() => setMinAgeDropDown(false)}
                      >
                        <div className="absolute flex h-40 w-full flex-col gap-3 overflow-y-auto rounded-b-lg border border-[#E2E8F0] bg-white px-2 py-3">
                          {minAge?.map((item, idx) => (
                            <div key={idx} className="flex gap-1">
                              <img
                                onClick={() =>
                                  setProjectDetailState((prev) => ({
                                    ...prev,
                                    minAge: item
                                  }))
                                }
                                className="cursor-pointer"
                                alt="both"
                                src={
                                  projectDetailsState.minAge === item
                                    ? "/images/enable-radio.svg"
                                    : "/images/disabled-radio.svg"
                                }
                              />
                              <span
                                className={`font-InterRegular text-ft14-19.6 ${item === projectDetailsState.minAge ? "text-darkGrey-20" : "text-grey-14"} `}
                              >
                                {item}
                              </span>
                            </div>
                          ))}
                        </div>
                      </ClickOutside>
                    )}
                  </div>
                  <div className="relative z-50 w-[69px]">
                    <div
                      onClick={() => {
                        setMaxAgeDropDown(!maxAgeDropDown);
                      }}
                      className={`flex h-[32px]  w-full cursor-pointer items-center justify-between ${maxAgeDropDown ? "" : "rounded-br-lg"} rounded-tr-lg border border-[#E2E8F0] px-3 py-1.5`}
                    >
                      {projectDetailsState.maxAge}{" "}
                      <img src="/images/down-arrow.svg" alt="" />
                    </div>
                    {maxAgeDropDown && (
                      <ClickOutside
                        isOpen={maxAgeDropDown}
                        onClickOutside={() => setMaxAgeDropDown(false)}
                      >
                        <div className="absolute flex h-40 w-full flex-col gap-3 overflow-y-auto rounded-b-lg border border-[#E2E8F0] bg-white px-2 py-3">
                          {maxAge?.map((item, idx) => (
                            <div key={idx} className="flex gap-1">
                              <img
                                onClick={() =>
                                  setProjectDetailState((prev) => ({
                                    ...prev,
                                    maxAge: item
                                  }))
                                }
                                className="cursor-pointer"
                                alt="both"
                                src={
                                  projectDetailsState.maxAge === item
                                    ? "/images/enable-radio.svg"
                                    : "/images/disabled-radio.svg"
                                }
                              />
                              <span
                                className={`font-InterRegular text-ft14-19.6 ${item === projectDetailsState.maxAge ? "text-darkGrey-20" : "text-grey-14"} `}
                              >
                                {item}
                              </span>
                            </div>
                          ))}
                        </div>
                      </ClickOutside>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-3">
              <FloatingLabel
                name={"interests"}
                label={"Interests"}
                onBoard={true}
                isStar={true}
                isSmall={true}
                height={"h-11.2"}
                width={"w-108.7"}
                isInfo={true}
                isTool={true}
                value={projectDetailsState.interest}
                onChange={handleInterestsChange}
                component={
                  <div className="absolute -top-0 left-24 flex size-full ">
                    <img
                      src="/images/arrowBlack.svg"
                      alt="Arrow Black"
                      className=""
                    />
                    <p className="-ml-0.5 h-21 w-full min-w-[254px] max-w-150 overflow-auto rounded bg-black-12 px-2 py-0.5 font-InterRegular text-ft14-20 text-white ">
                      Enter any demographics, interests, or behaviors of the
                      target audience that would be important to know about this
                      project.
                    </p>
                  </div>
                }
                state={showInfoSolution}
                onMouseOver={() => setShowInfoSolution(true)}
                onMouseOut={() => setShowInfoSolution(false)}
                maxLength={500}
              />
            </div>
          </div>
        </div>
        <div className=" mt-4 flex items-center">
          <span className=" font-InterRegular text-ft16-19.36 text-[#A0AEC0]">
            {`Select the type of ${projectTypeState?.platformName === "Google" ? "Google" : projectTypeState?.platformName === "Facebook" ? "Facebook " : projectTypeState?.platformName === "Instagram" ? "Instagram" : projectTypeState?.platformName === "Bing" ? "Bing" : "LinkedIn"} ad that you want to create.`}
          </span>{" "}
          <p className="w-max text-red-11">*</p>
        </div>
        <div className=" flex gap-3">
          <div
            className={`mr-4 mt-6 flex h-[100px] w-35 cursor-pointer flex-col items-center justify-center rounded-15px bg-white  ${projectDetailsState.adType === "imageAd" ? " block border border-green-12" : "hidden shadow-9xl"}  `}
          >
            <img
              src={
                projectDetailsState.adType === "imageAd"
                  ? "/images/image-ad-selected.svg"
                  : "/images/image-ad-unselected.svg"
              }
              alt="ico"
            />
            <span
              className={`mt-1 px-2 font-InterRegular text-ft14-19.6 ${projectDetailsState.adType === "imageAd" ? "!text-green-12" : "text-[#A0AEC0]"}`}
            >
              Image Ad
            </span>
          </div>
          <div
            className={`mr-4 mt-6 flex h-[100px] w-35 cursor-pointer flex-col items-center justify-center rounded-15px bg-white  ${projectDetailsState.adType === "textAd" ? " block border border-green-12" : "hidden shadow-9xl"}  `}
          >
            <img
              src={
                projectDetailsState.adType === "textAd"
                  ? "/images/text-ad-active.svg"
                  : "/images/text-ad-inactive.svg"
              }
              alt="ico"
            />
            <span
              className={`mt-1 px-2 font-InterRegular text-ft14-19.6 ${projectDetailsState.adType === "textAd" ? "!text-green-12" : "text-[#A0AEC0]"}`}
            >
              Text Ad
            </span>
          </div>
        </div>
        <div
          className={` ${projectDetailsState.adType === "imageAd" ? "block" : "hidden"}`}
        >
          <div className=" mt-5 font-InterRegular text-ft16-19.36 text-[#A0AEC0]">
            What image would you like to use for this ad?
          </div>{" "}
          <div className="mt-5 flex w-full max-w-[828px] flex-row flex-wrap items-center">
            {uploadType.map((item, index) => {
              const isLastIndex = index === uploadType.length - 1;

              return (
                <div
                  className={`mb-4 mr-4 flex h-[100px] w-auto min-w-35 max-w-[157px] cursor-pointer flex-col items-center justify-center rounded-15px bg-white ${projectDetailsState.imageType === item.value ? "border border-green-12" : "shadow-9xl"}`}
                  key={index}
                  onClick={() => {
                    if (isLastIndex) {
                      setProjectDetailState((prev) => ({
                        ...prev,
                        imageType: "aiGeneratedImage",
                        selectedImage: null // Ensure no image is selected for the last index
                      }));
                    } else {
                      setCurrentPopup(item.value);
                    }
                  }}
                >
                  <img
                    className={`${
                      projectDetailsState.imageType === item.value &&
                      projectDetailsState.selectedImage
                        ? "size-full rounded-2xl object-cover"
                        : "size-auto"
                    } `}
                    src={
                      projectDetailsState.imageType === item.value
                        ? isLastIndex
                          ? item.activeIcon
                          : (projectDetailsState.selectedImage ??
                            item.activeIcon)
                        : item.inactiveicon
                    }
                    alt="icon"
                  />

                  {projectDetailsState.imageType === item.value &&
                    !projectDetailsState.selectedImage && (
                      <span
                        className={`mt-1 px-3 text-center font-InterRegular text-ft14-19.6 ${projectDetailsState.imageType === item.value ? "text-green-12" : "text-[#A0AEC0]"}`}
                      >
                        {item.name}
                      </span>
                    )}
                  {projectDetailsState.imageType !== item.value && (
                    <span
                      className={`mt-1 px-3 text-center font-InterRegular text-ft14-19.6 ${projectDetailsState.imageType === item.value ? "text-green-12" : "text-[#A0AEC0]"}`}
                    >
                      {item.name}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex w-full items-center justify-center gap-x-10 px-24">
          <div
            className=" mt-7 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent px-2 font-InterBold text-ft14-19.6 text-grey-14"
            onClick={() => {
              setStep(1);
            }}
          >
            BACK
          </div>
          <button
            disabled={
              !projectDetailsState?.projectName ||
              !projectDetailsState?.projectDescription ||
              !projectDetailsState?.adType ||
              projectDetailsState.projectDescription?.length < 10 ||
              (projectDetailsState.adType === "imageAd" &&
                !projectDetailsState?.imageType) ||
              !projectDetailsState?.interest
                ? true
                : false
            }
            className={` mt-7 flex h-8.7 w-75 cursor-pointer ${
              !projectDetailsState?.projectName ||
              !projectDetailsState?.projectDescription ||
              !projectDetailsState?.adType ||
              projectDetailsState.projectDescription?.length < 10 ||
              (projectDetailsState.adType === "imageAd" &&
                !projectDetailsState?.imageType) ||
              !projectDetailsState?.interest
                ? "opacity-50"
                : "opacity-100"
            } items-center justify-center rounded-3xl bg-green-12 px-2 font-InterBold text-ft14-19.6 text-white`}
            onClick={(e) => {
              e.preventDefault();
              hanldeCreateProject();
              // navigate(
              //   // `/dashboard/brand/details/project/finalReview/${item.id}/template`
              //   `/dashboard/brand/details/project/finalReview/:{id}/template`
              // );
            }}
          >
            NEXT
          </button>
        </div>
      </div>
      {openPopup()}
    </div>
  );
}
