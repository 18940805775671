import React, { useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";

const InvoicePopup = ({
  selectedInvoiceId,
  selectedInvoiceUrl,
  onClose,
  setShowPopup
}) => {
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(selectedInvoiceUrl);
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        setPdfData(await pdfDoc.saveAsBase64({ dataUri: true }));
      } catch (error) {
        console.error("Error fetching or processing PDF:", error);
      }
    };

    fetchPdf();
  }, [selectedInvoiceUrl]);

  const handleInvoicePopUp = () => {
    setShowPopup("INVOICE_LIST");
  };

  const downloadPdf = () => {
    if (pdfData) {
      saveAs(pdfData, "invoice.pdf");
    }
  };

  return (
    // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-grey-23 bg-opacity-50 font-InterRegular backdrop-blur-md">
      <div className="h-90per w-60per overflow-hidden rounded-15px bg-white shadow-lg 2xl:w-45per">
        <div className="size-full overflow-y-auto">
          <div className="flex w-full justify-end p-3">
            <button className="pr-3 pt-2.5" onClick={onClose}>
              <img src="/images/close-icon.svg" alt="Close" />
            </button>
          </div>
          <div className="mx-auto w-65per">
            <div className="mt-1">
              <img
                src="/images/adperfect-logo-dark.svg"
                className="h-20 w-[331px]"
                alt="Adperfect-logo"
              />
            </div>
            <div className="px-10">
              <div className="mt-1 flex items-center justify-between border-b border-grey-5 pb-4 text-ft24-32 font-semibold">
                <div className="flex text-ft20-28 font-semibold text-grey-12">
                  Invoice {selectedInvoiceId?.id}
                </div>
              </div>

              {/* PDF Viewer */}
              <div className="border-b border-grey-5 pb-5">
                {pdfData ? (
                  <iframe
                    src={pdfData}
                    style={{ width: "100%", height: "500px" }}
                    title="PDF Viewer"
                  />
                ) : (
                  <p>Loading PDF...</p>
                )}
              </div>

              <div className="flex w-full justify-center">
                <div className="flex w-80per justify-between gap-3 pt-14 text-ft14-21">
                  <button
                    className="w-full rounded-15px border border-grey-14 px-4 py-2 text-grey-14"
                    onClick={handleInvoicePopUp}
                  >
                    BACK
                  </button>
                  <button
                    className="w-full rounded-15px bg-green-12 px-4 py-1 text-white hover:bg-green-10"
                    onClick={downloadPdf}
                  >
                    DOWNLOAD PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePopup;
