import React, { useEffect, useState } from "react";
import {
  plansDataMonthly,
  plansDataAnnual,
  httpConstants
} from "../../constants";
import Utils from "../../utility";
import UpgradeSubscription from "./upgradeSubscription";
import {
  upgradeSubscriptionPlan,
  verifyUserByAuth
} from "../../services/userService";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  updateUserDetail
} from "../../slices/userSlice";
import { sessionManager } from "../../managers/sessionManager";

const PricingPopup = ({ handlePricingPopup }) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("info"));
  const isPlanPurchased = userInfo?.subscriptionDetails;

  const [planBasic, setPlanBasic] = useState(false);
  const [planData, setPlanData] = useState(plansDataMonthly || []);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planUrl, setPlanUrl] = useState("");
  const [upgradeSubscriptionOpen, setUpgradeSubscriptionOpen] = useState(false);
  const [priceId, setPriceId] = useState("");

  const handleUpgradeSubscriptionPopup = () => {
    setUpgradeSubscriptionOpen(!upgradeSubscriptionOpen);
  };
  // FUNCTION TO UPDATE THE SUBSCRIPTION PLAN
  const updateSubscriptionDetails = async () => {
    const token = String(
      sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)
    );

    if (!priceId) {
      Utils.failureToastMessage("You have not selected any plan yet");
      return;
    }

    try {
      dispatch(showLoader());
      const response = await upgradeSubscriptionPlan({
        priceId: priceId
      });
      if (response) {
        if (response?.responseCode === 200) {
          handleUpgradeSubscriptionPopup();
          handlePricingPopup();
          dispatch(showLoader());
          setTimeout(async () => {
            const authDetails = await verifyUserByAuth(token);
            dispatch(updateUserDetail(authDetails?.responseData));
            // Hide the loader
            Utils.successToastMessage(
              "Your subscription plan has been successfully updated"
            );
            dispatch(hideLoader());
          }, 10000); // 5000 milliseconds = 5 seconds
        } else {
          Utils.failureToastMessage(
            "An issue occurred while updating the subscription plan"
          );
          handleUpgradeSubscriptionPopup();
          return;
        }
      }
    } catch (err) {
      Utils.failureToastMessage(
        "An error occurred while updating the subscription plan"
      );
      handleUpgradeSubscriptionPopup();
    } finally {
      // dispatch(hideLoader());
    }
  };
  const handlePlan = () => {
    dispatch(showLoader());
    setTimeout(() => {
      setPlanBasic(!planBasic);
      setPlanUrl("");
      setPriceId("");
      dispatch(hideLoader());
    }, 500);
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan.title);
    setPlanUrl(plan?.planUrl);
    setPriceId(plan.priceId);
  };

  const findMatchingPlan = (
    matchPriceIdResult,
    plansDataMonthly,
    plansDataAnnual
  ) => {
    const [title, planTime] = matchPriceIdResult;

    if (!title || !planTime) {
      return null;
    }

    const plansData = planTime === "month" ? plansDataMonthly : plansDataAnnual;

    const matchingPlan = plansData.find((plan) => plan.title === title);

    return matchingPlan || null;
  };

  const checkPlanStatus = (plan, currentPlan) => {
    return (
      plan.title === currentPlan[0] &&
      ((currentPlan[1] === "month" && plan.amount == currentPlan[2]) ||
        (currentPlan[1] === "year" && plan.amount == currentPlan[2]))
    );
  };

  useEffect(() => {
    const matchedPlan = findMatchingPlan(
      Utils.getSubscriptionPlanInfo(isPlanPurchased?.priceId),
      plansDataMonthly,
      plansDataAnnual
    );
    // handleSelectPlan(matchedPlan);
    if (matchedPlan?.planTime === "year") {
      setPlanBasic(true);
    } else {
      setPlanBasic(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (planBasic) {
      setPlanData(plansDataAnnual);
    } else {
      setPlanData(plansDataMonthly);
    }
  }, [planBasic]);

  const handleUpgrade = () => {
    if (planUrl && !isPlanPurchased) {
      handlePricingPopup();
      window.location.href = `${planUrl}?client_reference_id=${userInfo?.id}`;
    } else {
      handleUpgradeSubscriptionPopup();
    }
  };

  const currentPlan = Utils.getSubscriptionPlanInfo(isPlanPurchased?.priceId);

  return (
    <div className="fixed right-0 top-0 z-50 flex size-full items-center justify-center bg-blurDark px-15 backdrop-blur-sm">
      {upgradeSubscriptionOpen && (
        <UpgradeSubscription
          handleUpgradeSubscriptionPopup={handleUpgradeSubscriptionPopup}
          updateSubscriptionDetails={updateSubscriptionDetails}
        />
      )}
      <div className="h-90per cursor-pointer overflow-auto rounded-15px bg-white p-7 scrollbar-hide">
        <img
          onClick={handlePricingPopup}
          className="ml-auto cursor-pointer"
          src="/images/close-icon.svg"
          alt="Close"
        />
        <div className="px-22.5">
          <h1 className="text-center font-InterBold text-ft32-41.6 text-black-9">
            Pricing
          </h1>
          <div className="flex justify-center">
            <p className="mt-2.5 w-50per text-center font-InterRegular text-ft18-25.2 text-grey-14">
              {`Choose from one of our plans below to ${
                isPlanPurchased ? "upgrade your current" : "buy"
              } subscription plan.`}
            </p>
          </div>
          <div className="mt-4.5 flex items-center justify-center gap-3.2">
            <p
              className={`${
                !planBasic ? "text-green-12" : "text-grey-14"
              } font-InterRegular text-ft16-19.36`}
            >
              Monthly
            </p>
            <img
              className="cursor-pointer"
              onClick={handlePlan}
              src={
                planBasic
                  ? "/images/enable-switch.svg"
                  : "/images/disable-switch.svg"
              }
              alt="Toggle Plan"
            />
            <p
              className={`${
                planBasic ? "text-green-12" : "text-grey-14"
              } font-InterRegular text-ft16-19.36`}
            >
              Annual
            </p>
          </div>
          <div className="mt-8 flex h-full items-center justify-center">
            {planData?.map((item, idx) => (
              <div
                key={idx}
                onClick={() =>
                  isPlanPurchased
                    ? checkPlanStatus(item, currentPlan)
                      ? ""
                      : handleSelectPlan(item)
                    : handleSelectPlan(item)
                }
                className={`relative z-100 ${selectedPlan === item.title ? "h-571px" : " h-130 "} w-75 rounded-15px border ${
                  selectedPlan === item.title
                    ? "border-green-12"
                    : "border-grey-EEEEEE"
                } cursor-pointer bg-white`}
              >
                {item.title === "Business" && (
                  <div className="flex h-27px items-center justify-center rounded-t-15px bg-green-12 font-InterRegular text-ft12-16 text-white shadow-priceCard">
                    Most popular
                  </div>
                )}
                <div className="absolute left-20.5 top-12.2 text-center">
                  <h1 className="font-InterBold text-ft24-32 text-green-12">
                    {item.title}
                  </h1>
                  <p className="font-InterRegular text-ft14-19.6 text-grey-12">
                    Start now for
                  </p>
                  <div className="flex items-center justify-center">
                    <p className="font-InterBold text-ft24-32 text-black-14171B">
                      ${item.amount}
                    </p>
                    <p className="pl-1 font-InterRegular text-ft12-16 text-black-12">
                      / {planBasic ? "year" : "month"}
                    </p>
                  </div>
                </div>
                <div className="absolute left-7 top-30">
                  <div className="my-5 ml-1.7 w-50 border border-grey-EEEEEE"></div>
                  <p className="mb-3 font-InterSemibold text-ft18-25.2 text-grey-14">
                    Includes
                  </p>
                  <p className="mb-2 pr-3 font-InterRegular text-ft14-19.6 text-grey-14">
                    Everything in Freelance, plus
                  </p>
                  {item.data?.map((data, index) => (
                    <div
                      key={index}
                      className="mt-3 flex w-90per items-center justify-start gap-9px px-3"
                    >
                      <img alt="green tick" src="/images/green-tick.svg" />
                      <span className="font-InterRegular text-ft16-19.36 text-grey-14">
                        {data}
                      </span>
                    </div>
                  ))}
                </div>
                <button
                  disabled={selectedPlan === item.title ? false : true}
                  onClick={handleUpgrade}
                  className={`absolute bottom-5.5 ${selectedPlan === item.title ? "opacity-100" : "opacity-50"} left-19 flex h-8.7 w-30.7 items-center justify-center rounded-2xl font-InterBold text-ft10-16 ${
                    isPlanPurchased
                      ? checkPlanStatus(item, currentPlan)
                        ? "border border-grey-14 text-grey-14"
                        : "bg-green-12 text-white"
                      : "bg-green-12 text-white"
                  }`}
                >
                  {isPlanPurchased
                    ? checkPlanStatus(item, currentPlan)
                      ? "CURRENT PLAN"
                      : "UPGRADE"
                    : "BUY PLAN"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPopup;
