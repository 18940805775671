import React from "react";

export default function NewItem({
  content,
  setImport = () => {},
  setprojectButtonVisible
}) {
  return (
    <div
      className="flex h-78 w-full max-w-81.5 cursor-pointer flex-col items-center justify-center rounded-2xl border border-grey-21 px-4 text-center"
      onClick={() => {
        setImport(true);
        if (typeof setprojectButtonVisible === "function") {
          setprojectButtonVisible(true);
        }
      }}
    >
      <img src="/images/add-icon.svg" />
      <span className=" mt-2 font-InterRegular text-ft18-25.2 text-grey-12">
        {content}
      </span>
    </div>
  );
}
