/* eslint-disable tailwindcss/no-contradicting-classname */
import React, { useRef, useState, useEffect } from "react";
import NewItem from "../../common/components/addNewItem";
import ImportProjects from "../welcome/project/index";
import { deleteProject } from "../../services/userService";
import Utils from "../../utility";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../common/components/popup/modal";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../slices/userSlice";

const Project = ({
  setprojectButtonVisible,
  projectButtonVisible,
  step,
  setStep,
  brandDetails,
  projectList,
  getProjectListFnx,
  query,
  searchedProjectList,
  importProject,
  setImportProject
}) => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const popupRef = useRef();
  const currentUrl = window.location.href;

  const projectTemplateIds = currentUrl.split("/");
  useEffect(() => {
    if (projectTemplateIds[7] && projectTemplateIds[7] !== "isGenerated") {
      setImportProject(true);
    } else if (
      projectTemplateIds[7] &&
      projectTemplateIds[7] === "isGenerated"
    ) {
      setImportProject(true);
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return importProject ? (
    <ImportProjects
      setImportProject={setImportProject}
      setprojectButtonVisible={setprojectButtonVisible}
      setStep={setStep}
      step={step}
      brandDetails={brandDetails}
      projectList={projectList}
    />
  ) : (
    <div className="flex flex-col">
      <span className="font-PoppinsSemiBold text-ft24-32 text-grey-12">
        Project
      </span>
      <span className=" mt-2 font-PoppinsRegular text-ft14-21 text-grey-14">
        Select a project to work on
      </span>
      <div className="mt-3 grid grid-cols-4 gap-5 gap-x-6">
        {(query.length > 0 ? searchedProjectList : projectList).map(
          (item, index) => {
            return (
              <ProjectItem
                item={item}
                key={index.toString()}
                selectedId={selectedId}
                toggleEdit={toggleEdit}
                setToggleEdit={setToggleEdit}
                setSelectedId={setSelectedId}
                popupRef={popupRef}
                onDelete={() => {
                  getProjectListFnx();
                }}
              ></ProjectItem>
            );
          }
        )}
        <NewItem
          content={"Start a new project"}
          setImport={setImportProject}
          projectButtonVisible={projectButtonVisible}
          setprojectButtonVisible={setprojectButtonVisible}
        />
      </div>
    </div>
  );
};

export default Project;

const ProjectItem = (props) => {
  const [showModal, setModal] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    item,
    selectedId,
    setSelectedId,
    toggleEdit,
    setToggleEdit,
    popupRef,
    onDelete
  } = props;

  const handleProjectDelete = async (projectId) => {
    try {
      dispatch(showLoader());
      const [error, response] = await Utils.parseResponse(
        deleteProject(projectId)
      );

      if (error || !response) {
        toast.error("Error");
        return;
      }

      toast.success("Project deleted successfully");
      onDelete?.();
    } catch (err) {
      toast.error("An unexpected error occurred");
    } finally {
      dispatch(hideLoader());
      setModal(false);
    }
  };

  const handleDeleteButtonClick = (item) => {
    setSelectedId(item?.id);
    setModal(true); // Open the modal
    setToggleEdit(false);
  };

  return (
    <div className="flex flex-col rounded-2xl">
      <div
        className={`relative flex h-[191px] w-full max-w-[295px] flex-col justify-start rounded-2xl ${item.createdImage || item.selectedImage ? "bg-grey-18" : "bg-white"} shadow-12xl`}
      >
        <div
          className="absolute -right-1 -top-1 flex size-8 cursor-pointer items-center justify-center rounded-full bg-white shadow-7xl"
          onClick={() => {
            setSelectedId(item.id);
            setToggleEdit(!toggleEdit);
          }}
        >
          <img src="/images/pen-icon.svg" alt="Pen icon" />
        </div>

        {toggleEdit && selectedId === item.id && (
          <div
            ref={popupRef}
            className={`absolute right-3  top-3 z-[90] flex h-[100px] w-[166px] flex-col rounded-2xl bg-white shadow-8xl  transition-all delay-500 ease-linear`}
          >
            <span
              className="cursor-pointer rounded-2xl px-5 py-3 font-InterRegular text-ft18-25.2 text-grey-16 hover:bg-green-12  hover:text-white"
              onClick={() =>
                navigate(
                  `/dashboard/brand/details/project/finalReview/${item.id}`
                )
              }
            >
              View Project
            </span>
            <span
              className="cursor-pointer rounded-2xl px-5 py-3 font-InterRegular  text-ft18-25.2 text-grey-16 hover:bg-green-12 hover:text-white"
              onClick={() => {
                handleDeleteButtonClick(item);
              }}
            >
              Delete Project
            </span>
          </div>
        )}
        {showModal && (
          <Modal
            heading={"Confirm Project Deletion"}
            content={
              "Are you sure you want to permanently delete this project? This action will remove all associated projects and ads and cannot be undone. Please confirm that you want to proceed with deleting the project."
            }
            buttonText={"DELETE PROJECT"}
            cancelText={"CANCEL"}
            closeModal={() => setModal(false)} // Close modal function
            functionCall={() => handleProjectDelete(selectedId)} // Function to call on delete
          />
        )}

        <div className="absolute left-2.5 top-2.5">
          {(item.platformName === "Facebook" ||
            item.platformName === "facebook-instagram") && (
            <img src="/images/facebook-logo.svg" className="size-7.5" />
          )}
          {item.platformName === "Bing" && (
            <img src="/images/bing-logo.svg" className="size-7.5" />
          )}
          {item.platformName === "Google" && (
            <img src="/images/google-logo.svg" className="size-7.5" />
          )}
          {item.platformName === "LinkedIn" && (
            <img src="/images/linkedin-logo.svg" className="size-7.5" />
          )}
          {item.platformName === "Instagram" && (
            <img src="/images/instagram-logo.svg" className="size-7.5" />
          )}
        </div>

        {item.typeOfAd === "image" ? (
          <div className="">
            {item.createdImage || item.selectedImage ? (
              <img
                className=" h-[191px] w-[295px] rounded-2xl object-contain"
                src={item.createdImage || item.selectedImage}
              />
            ) : (
              <>
                <div className=" grid grid-cols-2 gap-2 p-2 opacity-30">
                  <img
                    className="h-21 w-full rounded object-cover"
                    src={item?.images[0]}
                  />
                  <img
                    className="h-21 w-full rounded object-cover"
                    src={item?.images[1]}
                  />

                  <img
                    className="h-21 w-full rounded object-cover"
                    src={item?.images[2]}
                  />
                  <img
                    className="h-21 w-full rounded object-cover"
                    src={item?.images[3]}
                  />
                </div>
                <button
                  onClick={() => {
                    navigate(
                      `/dashboard/brand/details/project/finalReview/${item?.id}`
                    );
                  }}
                  className="absolute bottom-2.5 left-2.5 z-100 h-8 w-auto rounded-full bg-green-12 px-4 font-InterMedium text-ft12-16 text-white  "
                >
                  Resume
                </button>
              </>
            )}
          </div>
        ) : (
          <div className="px-5.5 pb-5.5 pt-14">
            <p className="font-InterSemibold text-ft16-19.36 text-blue-2E8ADD">
              {item.projectName}
            </p>
            <p className="truncate-google-text-project font-InterRegular text-ft14-20 text-grey-62666A">
              {item.projectDescription}
            </p>
          </div>
        )}
      </div>
      <span className="mb-2 mt-3 font-InterRegular text-ft12-16 text-grey-22">
        {Utils.shortenString(
          Utils.capitalizeFirstLetter(item?.platformName),
          30
        )}{" "}
        {item.projectType === "socialPost" ? "Social Post" : item.projectType}
      </span>
      <span className="mb-3 font-InterSemibold text-ft18-25.2 text-grey-16">
        {Utils.shortenString(item?.projectName, 30)}
      </span>
      <div
        className="flex h-8.7 w-30.7 cursor-pointer items-center justify-center rounded-3xl bg-green-12 px-2 font-InterBold text-ft10-16 text-white"
        onClick={() => {
          navigate(`/dashboard/brand/details/project/finalReview/${item?.id}`);
        }}
      >
        VIEW PROJECT
      </div>
    </div>
  );
};
