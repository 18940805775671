import React, { useEffect, useState } from "react";
import BuyMoreTokenPopup from "../../../modules/welcome/project/buyMoreTokenPop";
import { useDispatch, useSelector } from "react-redux";
import { callNotificationApi } from "../../../slices/userSlice";

export function RemainingToken() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.user?.info);
  const isPlanPurchased = userInfo?.subscriptionDetails;
  const remainingTokens = isPlanPurchased?.tokenCount;
  const [modalMoreToken, setModalMoreToken] = useState(false);
  const maxTokens = isPlanPurchased?.totalToken;
  const progressBarWidth = (remainingTokens / maxTokens) * 100;

  useEffect(() => {
    dispatch(callNotificationApi());

    const intervalId = setInterval(() => {
      dispatch(callNotificationApi());
    }, 900000); // 15 mint set to call notification api

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`absolute bottom-7per m-auto ${isPlanPurchased ? " flex" : "hidden"} justify-center pl-7`}
    >
      <div className="mt-4 h-[166px] w-auto rounded-3xl bg-[#021327] p-3">
        <div className="mt-3 h-[12px] w-auto overflow-hidden rounded-full bg-white">
          <div
            className="h-full bg-green-12"
            style={{
              width: `${progressBarWidth}%`,
              backgroundColor: "#17C471",
              borderRadius: "8px"
            }}
          ></div>
        </div>
        <div className="mt-2 flex p-1 font-semibold text-[#fff]">
          <span className=" whitespace-nowrap">
            {remainingTokens} tokens remaining
          </span>
          <img src="/images/token-icon.svg" className="ml-2" alt="Token Icon" />
        </div>
        <button
          className="mt-8 h-8.5 w-[186px] rounded-2xl border-2 border-white text-center font-bold text-[#fff]"
          onClick={() => setModalMoreToken(true)}
        >
          BUY MORE TOKENS
        </button>

        {modalMoreToken && (
          <BuyMoreTokenPopup
            heading={"Cancel More token"}
            content={"More token"}
            buttonText={"Cancel Token"}
            functionCall={() => setModalMoreToken(false)}
            closeModal={() => setModalMoreToken(false)}
            isFullScreen={true}
          />
        )}
      </div>
    </div>
  );
}
