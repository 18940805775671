import React from "react";

const Footer = () => {
  return (
    <div className="mt-auto flex flex-row justify-center space-x-1 pt-2 text-center font-InterRegular">
      <p className="font-InterRegular text-ft12-18 text-grey-14">
        Copyright © 2024
      </p>
      <p className="font-InterRegular text-ft12-18 text-green-12">AdPerfect</p>
      <p className="font-InterRegular text-ft12-18 text-grey-14">
        All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
