import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Utils {
  /**
   * Get item from localStorage
   * @param {string} key - The key to identify the item in localStorage
   * @returns {any|null} Parsed JSON data from localStorage, or null if the key does not exist
   */
  static localStorageGetItem = (key) => {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  };

  /**
   * Set item in localStorage
   * @param {string} key - The key to identify the item in localStorage
   * @param {any} data - Data to be stored in localStorage (will be JSON.stringify-ed)
   */
  static localStorageSetItem = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  /**
   * Remove item from localStorage
   * @param {string} key - The key to identify the item in localStorage
   */
  static localStorageRemoveItem = (key) => {
    localStorage.removeItem(key);
  };

  /**
   * This function is made to handle success and error callback!
   * @param {Promise} promise - The promise to be handled
   * @returns {Promise<Array>} A promise that resolves to an array with either [null, data] on success or [err] on failure
   */
  static async parseResponse(promise) {
    return promise.then((data) => [null, data]).catch((err) => [err]);
  }

  static generateUrl(url, params) {
    let i = 0,
      key;
    for (key in params) {
      if (i === 0) {
        url += "?";
      } else {
        url += "&";
      }
      url += key;
      url += "=";
      url += params[key];
      i++;
    }
    return url;
  }

  static shortenString(string, maxLength) {
    const charsToKeep = Math.floor((maxLength - 3) / 2);
    return string.length > maxLength
      ? string.substring(0, charsToKeep) +
          "..." +
          string.substring(string.length - charsToKeep)
      : string;
  }

  static successToastMessage(message) {
    toast.dismiss();
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      toastId: "successToast"
    });
  }
  static failureToastMessage(message) {
    toast.dismiss();
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      toastId: "failureToast"
    });
  }
  static capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }
  static addDays(date, days) {
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  }
  static formatTimestampNormal = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  // eslint-disable-next-line no-unused-vars
  static epochTimeToLocalDateString = (timestamp) => {
    if (!timestamp) return "N/A";

    // Convert to number in case it's passed as a string

    const timestampNumber = Number(timestamp);

    // Check if the timestamp is in seconds (10 digits) or milliseconds (13 digits)

    const isSeconds = timestampNumber.toString().length === 10;

    // If it's in seconds, convert it to milliseconds

    const date = new Date(isSeconds ? timestampNumber * 1000 : timestampNumber);

    return date.toLocaleDateString(); // Customize format as needed
  };

  static convertPlatformName = (platformName) => {
    switch (platformName) {
      case "Facebook":
        return "Facebook Ad";
      case "Instagram":
        return "Instagram Ad";
      case "Google":
        return "Google Ad";
      case "Bing":
        return "Bing Ad";
      case "LinkedIn":
        return "LinkedIn Ad";
      default:
        return "Ad";
    }
  };
  static isValidWebsiteUrl(url) {
    // Regular expression for URLs with or without www.
    const urlPattern =
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/|https:\/\/)(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?([\/\?#].*)?$/;

    // Test the input URL
    const isValidFormat = urlPattern.test(url);

    if (!isValidFormat) {
      return false;
    }

    // Further validation based on the number of dots and presence of 'www'
    const dotCount = (url.match(/\./g) || []).length;

    if (url.includes("www.")) {
      // If 'www' is present, there must be at least 2 dots
      return dotCount >= 2;
    } else {
      // If 'www' is not present, there must be at least 1 dot
      return dotCount >= 1;
    }
  }

  static getSubscriptionPlanInfo = (priceID) => {
    switch (priceID) {
      case process.env.REACT_APP_FREELANCE_MONTHLY_PLAN_PRICE_ID:
        // Here array indicates --> [planName, planTime, planPrice]
        return ["Freelance", "month", "12"];
      case process.env.REACT_APP_BUSINESS_MONTHLY_PLAN_PRICE_ID:
        return ["Business", "month", "24"];
      case process.env.REACT_APP_ENTERPRISE_MONTHLY_PLAN_PRICE_ID:
        return ["Enterprise", "month", "50"];
      case process.env.REACT_APP_FREELANCE_ANNUAL_PLAN_PRICE_ID:
        return ["Freelance", "year", "100"];
      case process.env.REACT_APP_BUSINESS_ANNUAL_PLAN_PRICE_ID:
        return ["Business", "year", "200"];
      case process.env.REACT_APP_ENTERPRISE_ANNUAL_PLAN_PRICE_ID:
        return ["Enterprise", "year", "400"];
      default:
        return ["No Plan purchased", "", ""];
    }
  };
  static formatDateInStringForm(epochTime) {
    const date = new Date(epochTime);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  }
  static formatTimeHavingTimeDateDay(epochTime) {
    const date = new Date(epochTime);

    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = date.toLocaleTimeString("en-US", options);

    if (isToday) {
      return `Today - ${time}`;
    } else if (isYesterday) {
      return `Yesterday - ${time}`;
    } else {
      const dateOptions = { year: "numeric", month: "long", day: "2-digit" };
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      return `${formattedDate} - ${time}`;
    }
  }

  static manageTimer = (
    resendStart,
    seconds,
    minutes,
    setSeconds,
    setMinutes,
    setResendStart,
    intervalId
  ) => {
    if (resendStart && seconds > 0) {
      setSeconds(seconds - 1);
    }
    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(intervalId);
        setResendStart(false); // Stop the resend process when the timer reaches zero
      } else {
        setSeconds(59);
        setMinutes(minutes - 1);
      }
    }
  };
  static fixUrl(url) {
    return url
      .replace(/\s/g, "%20")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29");
  }
}
