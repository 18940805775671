import React from "react";

const PreviewProject = () => {
  return (
    <div className="size-full">
      <img className="size-96" src={""} />
    </div>
  );
};

export default PreviewProject;
