import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  getListOfNotification,
  markNotificationRead,
  searchBrand,
  searchProject
} from "../../services/userService";
import Utils from "../../utility";
import Notification from "./notification";
import { useNavigate } from "react-router-dom";
import BrandListDropdown from "./brandListDropdown";
import ClickOutside from "./clickOutside";
import { useDispatch, useSelector } from "react-redux";
import {
  removeUser,
  removeUserDetail,
  stopNotificationApi,
  updateUnreadNotification
} from "../../slices/userSlice";
import { useCreateSessionService } from "./createSession";

const Header = ({
  isProject = null,
  setIsProject = () => {},
  manage,
  setManage,
  setSearchedBrands = () => {},
  setSearchedProjectList = () => {},
  query,
  setQuery,
  setLoading,
  setNavigate = () => {},
  isFromUserDetails = false,
  showDropdown = true,
  topPadding = "pl-2per",
  isProjectSearch = false,
  setImportProject,
  setStep,
  setBrandTab = () => {},
  step
}) => {
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const { createSessionForUser } = useCreateSessionService();
  const user = useSelector((state) => state.user);
  const brandDetails = useSelector((state) => state?.user?.brandDetails);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userInfo = JSON.parse(localStorage.getItem("info"));
  const [showSearch, setShowSearch] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [value] = useDebounce(query, 400);
  const [notificationList, setNotificationList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [hasMore, setHasMore] = useState(true);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [unReadNotificationIds, setUnReadNotificationIds] = useState([]);
  const navigate = useNavigate();
  const handleLogout = async () => {
    await createSessionForUser(user?.info?.id);
    dispatch(removeUser({}));
    dispatch(removeUserDetail({}));
    Utils.successToastMessage("Logout successfully");

    navigate("/");
  };
  const getSearchList = async () => {
    setLoading(true);
    let [error, response] = await Utils.parseResponse(searchBrand(value));
    if (error || !response) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setSearchedBrands(response?.responseData);
  };

  const getSearchedProjects = async () => {
    setLoading(true);
    let [error, response] = await Utils.parseResponse(
      searchProject(brandDetails?.id, value)
    );
    if (error || !response) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setSearchedProjectList(response?.responseData);
  };

  useEffect(() => {
    if (query.length > 0 && !isProjectSearch) {
      getSearchList();
    } else if (query.length > 0 && isProjectSearch) {
      getSearchedProjects();
    } else {
      setQuery("");
      setSearchedBrands([]);
      setSearchedProjectList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isProject && !isProjectSearch) {
      setQuery("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProject]);

  const searchBarRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setShowSearch(true);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    setShowSearch(false);
  };

  const handleSelect = (item) => {
    setIsProject(item);
    setNavigate(item);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // FUNCTION TO FETCH ALL NOTIFICATION LIST
  const fetchUserNotificationList = async () => {
    try {
      setLoadingNotification(true);
      const [error, response] = await Utils.parseResponse(
        getListOfNotification()
      );

      if (error || !response) {
        return;
      }

      if (response?.responseData?.notifications?.length > 0) {
        const unreadIds = response.responseData.notifications
          .filter((notification) => notification.isRead === false)
          .map((notification) => notification.id);
        setUnReadNotificationIds(unreadIds);

        dispatch(updateUnreadNotification(response.responseData?.unreadCount));

        setNotificationList(response.responseData.notifications);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(stopNotificationApi());
      setLoadingNotification(false);
    }
  };

  // FUNCTION TO MARK ALL NOTIFICATION AS READ
  const markAllNotificationRead = async () => {
    const requestData = {
      notificationIds: unReadNotificationIds
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const [error, response] = await Utils.parseResponse(
        markNotificationRead(requestData)
      );

      if (error) {
        return;
      } else {
        dispatch(updateUnreadNotification(0));
      }
    } catch (e) {
      console.error(e);
    } finally {
      // Empty
    }
  };
  const handleNotificationPopup = async () => {
    await fetchUserNotificationList();

    setNotificationOpen(!notificationOpen);
    if (user?.unreadNotificationCount != 0) {
      markAllNotificationRead();
    }
  };
  useEffect(() => {
    if (user?.notificationApiCall) {
      const fetchNotifications = async () => {
        try {
          setLoadingNotification(false);
          setNotificationList([]);
          await fetchUserNotificationList();
          // setHasMore(true);
          // setPage(0);
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };

      fetchNotifications();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div className={`${topPadding}`}>
      <div
        className={` flex h-20 w-full items-center ${isFromUserDetails || !showDropdown ? "justify-end" : "justify-between"} pt-0`}
      >
        {!isFromUserDetails && showDropdown && (
          <BrandListDropdown
            onSelectFn={handleSelect}
            name={isProject?.brandName}
          />
        )}

        <div className="flex items-center">
          {/* {isProject && (
            <div
              onClick={() => {
                setImportProject(true);
                setStep(1);
                setBrandTab("PROJECTS");
              }}
              className=" mr-5 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-2xl bg-green-12 px-2 text-ft12-16 text-white"
            >
              GENERATE
            </div>
          )} */}

          {((isProject && step != 4) ||
            (pathName.includes("finalReview") && step != 4)) && (
            <div
              onClick={() => {
                if (pathName.includes("finalReview")) {
                  if (
                    typeof brandDetails?.id === "string" ||
                    typeof brandDetails?.id === "number"
                  ) {
                    navigate(
                      `/dashboard/brand/details/${brandDetails.id}/isGenerated`
                    );
                  } else {
                    console.error(
                      "Invalid ID: brandDetails.id should be a string or number, found:",
                      brandDetails?.id
                    );
                  }
                } else {
                  setImportProject(true);
                  setStep(1);
                  setBrandTab("PROJECTS");
                }
              }}
              className="mr-5 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-2xl bg-green-12 px-2 text-ft12-16 text-white"
            >
              GENERATE
            </div>
          )}
          <div className="flex w-auto items-center">
            {!showSearch /* Only show the search icon when showSearch is false */ &&
              (isProject || (isFromUserDetails && !isFocused) ? (
                <img
                  src="/images/search-icon-white.svg"
                  className={`size-6 cursor-pointer ${
                    pathName?.includes("settings") ||
                    pathName?.includes("user-details")
                      ? "hidden"
                      : "block"
                  }`}
                  onClick={() =>
                    isProjectSearch
                      ? setShowSearch(!showSearch)
                      : !isProject && setShowSearch(!showSearch)
                  }
                />
              ) : (
                <img
                  src="/images/search-icon.svg"
                  className="size-6 cursor-pointer"
                  onClick={() => !isProject && setShowSearch(!showSearch)}
                />
              ))}
            {showSearch && (
              <div
                className={`relative flex h-[33.39px] w-50 cursor-pointer items-center rounded-3xl border-0.5px ${isFocused ? "border-green-10" : "border-grey-9"} bg-white p-2 `}
              >
                <input
                  className="h-auto w-95per rounded-3xl border-none pl-2 outline-none placeholder:text-ft14-19.6 "
                  placeholder="Search"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
                <img
                  src="/images/search-icon.svg"
                  className={`size-6 cursor-pointer`}
                />
              </div>
            )}
            <div onClick={handleNotificationPopup} className="relative">
              {user?.unreadNotificationCount != 0 && (
                <div className=" absolute bottom-3.5 left-8 flex size-4.5 items-center justify-center rounded-full bg-red-11 font-InterRegular text-ft10-16 text-white">
                  {user?.unreadNotificationCount}
                </div>
              )}

              {isProject || isFromUserDetails ? (
                <img
                  src="/images/bell-icon-white.svg"
                  alt="Bell icon"
                  className="ml-5 size-6 cursor-pointer"
                />
              ) : (
                <img
                  src="/images/bell-icon.svg"
                  alt="Bell_icon"
                  className=" ml-5 size-6 cursor-pointer"
                />
              )}
            </div>
            <ClickOutside
              isOpen={manage}
              onClickOutside={() => setManage(false)}
            >
              <div
                className={`relative z-50 -mr-2 flex w-[252px] cursor-pointer items-center px-5 py-2 ${manage ? "rounded-t-15px bg-white shadow-13xl " : ""} `}
                onClick={() => setManage(!manage)}
              >
                <div className="flex items-center">
                  {userInfo?.profileImage && (
                    <img
                      src={userInfo?.profileImage}
                      alt=""
                      className="size-10 rounded-full object-cover"
                    />
                  )}
                  {!userInfo?.profileImage && (
                    <div className="ml-5 mt-1 flex size-10 items-center justify-center rounded-full bg-green-12 font-InterBold text-white">
                      {userDetails?.name?.charAt(0)?.toUpperCase()}
                    </div>
                  )}
                  <div className="ml-3 flex items-center gap-1">
                    <div className="w-34 truncate">
                      <span
                        className={` font-PoppinsSemiBold text-ft16-19.36 ${(isProject || isFromUserDetails) && !manage ? "text-white" : pathName?.includes("finalReview") && !manage ? "text-white" : "text-grey-12"}`}
                      >
                        {`${
                          String(userInfo?.profile?.firstName)
                            .charAt(0)
                            .toUpperCase() +
                          String(userInfo?.profile?.firstName).slice(1)
                        } ${
                          String(userInfo?.profile?.lastName)
                            .charAt(0)
                            .toUpperCase() +
                          String(userInfo?.profile?.lastName).slice(1)
                        }`}
                      </span>
                    </div>
                    {!isProject && !isFromUserDetails && (
                      <img
                        src="/images/arrow-icon-white.svg"
                        alt="arrow icon"
                        className="size-4 cursor-pointer"
                      />
                    )}
                    {(isProject || isFromUserDetails) && !manage && (
                      <img
                        src="/images/arrow-icon-white.svg"
                        alt="arrow icon"
                        className="size-6 cursor-pointer"
                      />
                    )}
                    {(isProject || isFromUserDetails) && manage && (
                      <img
                        src="/images/down-icon.svg"
                        className="size-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                {manage && (
                  <div
                    className={` absolute left-0 top-13 z-30 h-26 w-full rounded-b-15px bg-white shadow-13xl`}
                  >
                    <div
                      className="mt-5 flex cursor-pointer items-center pl-5 font-InterBold text-ft16-19.36 text-grey-14"
                      onClick={() => {
                        navigate("/user-details");
                        setIsProject(null);
                      }}
                    >
                      <img
                        src="/images/gear-logout.svg"
                        alt=""
                        className=" mr-4 size-4.5"
                      />
                      <span>Manage Account</span>
                    </div>

                    <div
                      className="mt-5 flex cursor-pointer items-center pl-5 font-InterBold text-ft16-19.36 text-grey-14"
                      onClick={handleLogout}
                    >
                      <img src="/images/exit.svg" className=" mr-4 size-4.5" />
                      <span>Logout</span>
                    </div>
                  </div>
                )}
              </div>
            </ClickOutside>
            <ClickOutside
              isOpen={notificationOpen}
              onClickOutside={handleNotificationPopup}
            >
              {notificationOpen && (
                <Notification
                  handleNotificationPopup={handleNotificationPopup}
                  loadingNotification={loadingNotification}
                  // hasMore={hasMore}
                  notificationList={notificationList}
                  fetchUserNotificationList={fetchUserNotificationList}
                />
              )}
            </ClickOutside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
