import Utils from "../../utility";
import { createSession } from "../../services/userService";

export const useCreateSessionService = () => {
  // const dispatch = useDispatch();

  const createSessionForUser = async (userId) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const [error, response] = await Utils.parseResponse(
        createSession(userId)
      );
      if (error) {
        Utils.failureToastMessage("Error while creating session of the user");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };
  return {
    createSessionForUser
  };
};
