/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useRef, useState } from "react";
import Utils from "../../../utility";

function FloatingLabel({
  label,
  onChange,
  value,
  onBoard = false,
  width = "w-full",
  height,
  isStar = false,
  name,
  isSelect = false,
  options = [],
  isSmall = false,
  isInfo = false,
  borderWidth = "border-2",
  borderColor = "border-grey-14",
  borderfocusColor = "border-green-12",
  borderRadius = "rounded-2xl",
  state,
  onMouseOver,
  onMouseOut,
  component,
  isTool = false,
  showSearch = false,
  googleFonts = [],
  isGreyd = false,
  maxLength = 50,
  isWidthFull = false,
  type,
  parentWidth
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchedFonts, setSearchedFonts] = useState([]);
  const [searchQuery, setSearchQuery] = useState(false);
  let combinedFonts = [
    ...new Set(
      [
        ...options.map((item) => item.family),
        ...googleFonts.map((item) => item.family)
      ].sort()
    )
  ];

  const ref = useRef();
  let dropdownOptions = [];

  if (options.length > 0) {
    if (options[0]?.family) {
      dropdownOptions = options.map((item) => item.family).sort();
    } else {
      dropdownOptions = options;
    }
  } else {
    dropdownOptions = ["Not found"];
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dropdownOptions = [];
      setSearchQuery("");
      setSearchedFonts([]);
    };
  }, []);

  const handleSelect = (selectedValue) => {
    onChange({ target: { name, value: selectedValue } });
    setIsOpen(false);
  };
  const handleFocus = (inputId) => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.focus();
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(true);
    let filteredData = combinedFonts.filter((item) =>
      String(item)
        .toLowerCase()
        .includes(String(event.target.value).toLowerCase())
    );
    // Sort the filtered data
    filteredData = filteredData.sort();
    setSearchedFonts(filteredData);
    if (event.target.value === "") {
      setSearchedFonts([]);
      setSearchQuery(false);
    }
  };

  return !onBoard ? (
    <div className="relative">
      <input
        type={type || "text"}
        id={name}
        name={name || "name"}
        required
        className={`peer block h-11.2 w-full appearance-none truncate ${borderRadius} ${borderWidth} ${borderColor} focus:${borderfocusColor} px-2.5 pb-2.5 pl-6 pt-4 text-ft16-19.36 text-grey-12 focus:outline-none focus:ring-0 dark:bg-transparent ${borderColor}`}
        placeholder=" "
        onChange={onChange}
        value={value || ""}
      />
      <label
        htmlFor={name}
        className={`
        absolute left-1 top-1 z-10 ml-3 origin-[0] -translate-y-5 scale-100 cursor-text
        rounded-full bg-white px-2 font-InterRegular text-ft18-25.2 text-grey-14
        duration-300 focus:translate-y-4 peer-placeholder-shown:top-1/2
        peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100  peer-focus:top-0.5 peer-focus:ml-3 peer-focus:-translate-y-4 peer-focus:scale-75 
        peer-focus:text-ft18-25.2 peer-focus:text-green-10 
        rtl:peer-focus:left-auto
        rtl:peer-focus:translate-x-1/4 peer-focus:dark:text-green-10
      `}
        onClick={() => handleFocus(name)}
      >
        {label}
      </label>
    </div>
  ) : !isSelect ? (
    <div className={`relative size-max ${isWidthFull && "w-full"}`}>
      <input
        type={type || "text"}
        className={`peer ${height} ${width}  ${isGreyd && "bg-grey-25"}  truncate  rounded-15px border border-grey-9  px-5   text-grey-12   outline-none focus:border-green-12`}
        id={name}
        name={name}
        value={value || ""}
        onChange={onChange}
        maxLength={name === "headline" ? 30 : maxLength}
        disabled={isGreyd}
      />
      <label
        htmlFor={name}
        className={` absolute -top-2.5 left-3.2 flex items-center bg-white px-1.2   font-InterRegular text-grey-14  peer-focus:text-green-12 ${isSmall ? "text-ft14-20" : "text-ft17-23.8"}`}
      >
        {label} {isStar && <p className="w-max text-red-11">*</p>}{" "}
        {isInfo && (
          <div>
            <img
              src="/images/Tooltip-icon.svg"
              alt="info"
              className="my-0.8  ml-2.5 size-3.2"
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            />
            {isTool && state && component}
          </div>
        )}
      </label>
    </div>
  ) : (
    <div className={`group relative size-max  ${parentWidth}`} ref={ref}>
      <div className="relative cursor-pointer">
        <div
          className={` ${height} ${width} cursor-pointer appearance-none items-start truncate rounded-15px border border-grey-9 px-5 pt-3 text-grey-16 outline-none focus:border-green-12  group-hover:border-green-12 `}
          onClick={() => {
            setIsOpen(!isOpen);
            setSearchedFonts([]);
            setSearchQuery(false);
          }}
        >
          {(() => {
            let displayValue = "";

            if (value) {
              if (String(value).length > 58) {
                displayValue = Utils.shortenString(value, 58);
              } else {
                displayValue =
                  String(value).charAt(0).toUpperCase() +
                  String(value).slice(1);
              }
            } else {
              let selectLabel = name;
              if (name === "businessType") {
                selectLabel = "Business Type";
              } else if (name === "heading" || name === "body") {
                selectLabel = "Font";
              } else if (name === "tone") {
                selectLabel = "Tone";
              }

              displayValue = `Select ${selectLabel}`;
            }

            return displayValue;
          })()}
        </div>
        {isOpen && (
          <div className="custom-dropdownOptions absolute  z-30 h-auto max-h-36 w-full overflow-auto rounded-b-[16.7px] rounded-t-[17.2px] border border-grey-9 bg-white scrollbar-hide">
            {dropdownOptions.length > 0 && showSearch && (
              <div className="flex items-center justify-start rounded-t-[17.2px] px-5 py-1">
                <input
                  className=" w-40 rounded-t-[17.2px] border-b-0.5px border-grey-9 px-1 text-grey-16 outline-none"
                  placeholder="Search font"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            )}
            {searchQuery && searchedFonts.length > 0 ? (
              <div>
                {searchedFonts.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="z-10 cursor-pointer truncate px-5 py-1 text-grey-16 hover:rounded-[20px] hover:bg-green-12 hover:text-white"
                      onClick={() => handleSelect(item)}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            ) : searchQuery && searchedFonts.length === 0 ? (
              <div className="px-5 pb-2.5 pt-2 text-black-9">No data found</div>
            ) : (
              <div>
                {dropdownOptions.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="z-10 cursor-pointer px-5 py-1 text-grey-16 hover:rounded-[20px] hover:bg-green-12 hover:text-white"
                      onClick={() => item !== "Not found" && handleSelect(item)}
                    >
                      {String(item).charAt(0).toUpperCase() +
                        String(item).slice(1)}
                    </div>
                  );
                })}
                {googleFonts?.length > 0 && (
                  <div className="cursor-pointer appearance-none items-start pt-3 text-grey-16 outline-none">
                    <div className=" px-5 pb-2.5 text-black-9 underline underline-offset-2">
                      Google fonts
                    </div>
                    {googleFonts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="z-10 cursor-pointer truncate px-5 py-1 text-grey-16 hover:rounded-[20px] hover:bg-green-12 hover:text-white"
                          onClick={() => handleSelect(item?.family)}
                        >
                          {item?.family}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <label
          htmlFor={name}
          className={`absolute -top-2.5 left-3.2 bg-white px-1.2 font-InterRegular ${isSmall ? "text-ft14-19.6" : "text-ft17-23.8"} flex text-grey-14 group-hover:text-green-12`}
        >
          {label} {isStar && <p className="w-max text-red-10">*</p>}{" "}
          {isInfo && (
            <img
              src="/images/Tooltip-icon.svg"
              alt="info"
              className="my-0.8 ml-2.5 size-3.2"
            />
          )}
        </label>
      </div>
      <div className="pointer-events-none absolute right-5 top-1/2 -translate-y-1/2 group-hover:text-green-12">
        <img src="/images/ArrowDown.svg" alt="arrow" className="h-1.8 w-2.8" />
      </div>
    </div>
  );
}

export default FloatingLabel;
