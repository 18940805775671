import React, { useEffect, useRef } from "react";

const Dropdown = ({
  setDropdown,
  dropdown,
  placeholder,
  value,
  setValue,
  list,
  disable = false,
  title,
  showSearch = false,
  handleSearch = () => {},
  searchValue,
  searchPlaceHolder,
  handleDeselect = () => {},
  isGreyd = false
}) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setDropdown]);
  return (
    <>
      <div className="group relative" ref={ref}>
        <div
          className={` relative ${isGreyd && "bg-grey-25"} flex h-11.2 w-full cursor-pointer appearance-none items-center justify-between truncate rounded-15px border border-grey-9 px-5 text-grey-16 outline-none ${!isGreyd && "focus:border-green-12  group-hover:border-green-12"}  `}
          onClick={() => {
            if (!disable) setDropdown(!dropdown);
          }}
        >
          <span
            className={` ml-2 mr-1 overflow-hidden text-ellipsis font-InterRegular text-ft14-19.6 ${disable || !value ? "text-opacity-50" : ""} text-grey-19`}
          >
            {value ? value : placeholder}
          </span>
          <img src="/images/ArrowDown.svg" alt="Down icon" />
        </div>
        {dropdown && (
          <div
            className={`absolute z-50 h-auto max-h-52 w-full overflow-y-auto rounded-15px border-0.5px border-grey-9 bg-white p-1 text-ft14-19.6 text-grey-19 scrollbar-hide`}
          >
            {showSearch && (
              <div className="flex items-center justify-start rounded-t-[17.2px] px-5 py-1">
                <input
                  className=" w-full rounded-t-[17.2px] border-b-0.5px border-grey-9 p-1 text-grey-16 outline-none"
                  placeholder={searchPlaceHolder || "Search"}
                  value={searchValue}
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            )}
            {list?.length > 0 ? (
              list?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="cursor-pointer truncate px-5 py-1 text-grey-16 hover:rounded-[20px] hover:bg-green-12 hover:text-white"
                    onClick={() => {
                      setValue(item);
                      setDropdown(false);
                      handleDeselect();
                    }}
                  >
                    {item?.name}
                  </div>
                );
              })
            ) : (
              <div className="px-5 py-1">No list found</div>
            )}
          </div>
        )}
        <label
          htmlFor={title}
          className={`absolute -top-2.5 left-3.2 flex bg-white px-1.2  font-InterRegular text-ft17-23.8 text-grey-14 ${!isGreyd && "group-hover:text-green-12"} `}
        >
          {title}
        </label>
      </div>
    </>
  );
};

export default Dropdown;
