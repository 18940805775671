import React, { useEffect, useState } from "react";
import {
  platformTypeAds,
  platformTypeSocial,
  projectType,
  linkedInAdsGoals,
  facebookAdsGoals,
  googleAdsGoals,
  socialGoals
} from "../../../constants";

export default function ProjectType({
  setStep,
  setprojectButtonVisible,
  projectTypeState,
  setProjectTypeState,
  setImportProject
}) {
  const [platformList, setPlaformList] = useState(platformTypeAds);
  const [goalList, setGoalsList] = useState(googleAdsGoals);

  useEffect(() => {
    if (projectTypeState?.projectType === "ad") {
      setPlaformList(platformTypeAds);
    } else if (projectTypeState?.projectType === "socialPost") {
      setPlaformList(platformTypeSocial);
    }
  }, [projectTypeState?.projectType]);

  useEffect(() => {
    if (projectTypeState?.projectType === "ad") {
      const goalsMap = {
        Google: googleAdsGoals,
        Bing: googleAdsGoals,
        Facebook: facebookAdsGoals,
        Instagram: facebookAdsGoals,
        LinkedIn: linkedInAdsGoals
      };
      setGoalsList(goalsMap[projectTypeState?.platformName] || googleAdsGoals);
    } else if (projectTypeState?.projectType === "socialPost") {
      setGoalsList(socialGoals);
    }
  }, [projectTypeState?.projectType, projectTypeState?.platformName]);

  const handleProjectTypeClick = (item) => {
    setProjectTypeState((prev) => ({
      ...prev,
      projectType: item.value,
      platformName: "",
      goal: ""
    }));
  };

  const handlePlatformClick = (item) => {
    if (projectTypeState?.projectType) {
      setProjectTypeState((prev) => ({
        ...prev,
        platformName: item.value,
        goal: ""
      }));
    }
  };

  const handleGoalClick = (item) => {
    if (projectTypeState?.platformName) {
      setProjectTypeState((prev) => ({
        ...prev,
        goal: item.value
      }));
    }
  };

  return (
    <>
      <h1 className="mb-3 font-InterBold text-ft24-32 text-[#718096]">
        New Project
      </h1>
      <div className="p-3 pl-6">
        <div className="flex items-center">
          <span className="font-InterRegular text-ft16-19.36 text-[#A0AEC0]">
            What type of project do you want to create?
          </span>
          <p className="w-max text-red-11">*</p>
        </div>
        <div className="mt-3 flex flex-row items-center">
          {projectType.map((item, index) => (
            <div
              key={index}
              className={`mr-4 flex h-[80px] w-auto min-w-30 max-w-[157px] cursor-pointer flex-col items-center justify-center rounded-15px bg-white ${projectTypeState.projectType === item.value ? "border border-green-12" : "shadow-9xl"}`}
              onClick={() => handleProjectTypeClick(item)}
            >
              <img
                src={
                  projectTypeState.projectType === item.value
                    ? item.activeIcon
                    : item.inactiveicon
                }
                alt="ico"
              />
              <span
                className={`mt-1 px-2 font-InterRegular text-ft12-16 ${projectTypeState.projectType === item.value ? "text-green-12" : "text-[#A0AEC0]"}`}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
        <div className="mt-4 flex items-center">
          <span className="font-InterRegular text-ft16-19.36 text-[#A0AEC0]">
            Select the platform that you need ads for.
          </span>
          <p className="w-max text-red-11">*</p>
        </div>
        <div className="mt-3 flex flex-row items-center">
          {platformList.map((item, index) => (
            <div
              key={index}
              className={`mr-4 flex h-[80px] w-auto min-w-30 cursor-pointer flex-col items-center justify-center rounded-15px bg-white ${projectTypeState.platformName === item.value ? "border border-green-12" : "shadow-9xl"}`}
              onClick={() => handlePlatformClick(item)}
            >
              <img
                src={
                  projectTypeState.platformName === item.value
                    ? item.activeIcon
                    : item.inactiveicon
                }
                alt="ico"
              />
              <span
                className={`mt-1 px-2 font-InterRegular text-ft12-16 ${projectTypeState.platformName === item.value ? "text-green-12" : "text-[#A0AEC0]"}`}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
        <div className="mt-4 flex items-center">
          <span className="font-InterRegular text-ft16-19.36 text-[#A0AEC0]">
            What is the goal of this project?
          </span>
          <p className="w-max text-red-11">*</p>
        </div>
        <div className="mt-3 flex w-full max-w-[828px] flex-row flex-wrap items-center">
          {goalList.map((item, index) => (
            <div
              key={index}
              className={`mb-4 mr-4 flex h-[80px] w-auto min-w-30 cursor-pointer flex-col items-center justify-center rounded-15px bg-white ${projectTypeState.goal === item.value ? "border border-green-12" : "shadow-9xl"}`}
              onClick={() => handleGoalClick(item)}
            >
              <img
                src={
                  projectTypeState.goal === item.value
                    ? item.activeIcon
                    : item.inactiveicon
                }
                alt="ico"
              />
              <span
                className={`mt-1 px-2 font-InterRegular text-ft12-16 ${projectTypeState.goal === item.value ? "text-green-12" : "text-[#A0AEC0]"}`}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
        <div className="flex w-full items-center justify-center gap-x-10 px-24">
          <div
            className="mt-7 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent px-2 font-InterBold text-ft14-19.6 text-grey-14"
            onClick={() => {
              setprojectButtonVisible(false);
              setImportProject(false);
            }}
          >
            BACK
          </div>
          <button
            disabled={
              !projectTypeState?.goal ||
              !projectTypeState?.platformName ||
              !projectTypeState?.projectType
                ? true
                : false
            }
            className={`mt-7 flex h-8.7 w-75 ${!projectTypeState?.goal || !projectTypeState?.platformName || !projectTypeState?.projectType ? "opacity-50" : "opacity-100"} cursor-pointer items-center justify-center rounded-3xl bg-green-12 px-2 font-InterBold text-ft14-19.6 text-white`}
            onClick={() => {
              setStep(2);
            }}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
}
