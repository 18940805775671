import { httpConstants } from "../constants";
import HTTPService from "../utility/httpService";
import { sessionManager } from "../managers/sessionManager";

const httpService = new HTTPService(process.env.REACT_APP_USER_MS);
const scrapService = new HTTPService(process.env.REACT_APP_SCRAP_MS);
const brandService = new HTTPService(process.env.REACT_APP_BRAND_MS);

export const verifyUser = (email) => {
  const endPoint = httpConstants.API_END_POINT.VERIFY_USER;

  const headers = {
    [httpConstants.HEADERS.ACCEPT]: "*/*"
  };
  const params = {
    email
  };
  return httpService.get(endPoint, params, headers);
};

export const createUser = (endPoint, body, config = {}) => {
  const headers = {
    [httpConstants.HEADERS.ACCEPT]: "*/*",
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`,
    [httpConstants.HEADERS.CONTENT_TYPE]:
      httpConstants.CONTENT_TYPE.APPLICATION_JSON
  };

  return httpService.post(endPoint, body, { ...config, headers });
};

export const scrapData = (url) => {
  return scrapService.get(
    httpConstants.API_END_POINT.SCRAP_UI,
    {
      url: url
    },
    {}
  );
};

export const getBrandsList = (requestData) => {
  const query = new URLSearchParams(requestData).toString();

  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.get(
    `${httpConstants.API_END_POINT.BRAND_LIST}?${query}`,
    {},
    {
      headers
    }
  );
};

export const getBrandDetails = (id) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.get(
    `${httpConstants.API_END_POINT.BRAND_DETAILS}/${id}`,
    {},
    {
      headers
    }
  );
};
export const getBrandProjectList = (requestData) => {
  const query = new URLSearchParams(requestData).toString();

  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.get(
    `${httpConstants.API_END_POINT.PROJECT_LIST}?${query}`,
    {},
    {
      headers
    }
  );
};

export const deleteBrand = (id) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.delete(
    `${httpConstants.API_END_POINT.DELETE_BRAND}?id=${id}`,
    {},
    {
      headers
    }
  );
};

export const verifyUserByAuth = (token) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${token ? token : String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.get(
    httpConstants.API_END_POINT.VERIFY_USER_SUB,
    {},
    { headers }
  );
};

export const createBrand = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.post(httpConstants.API_END_POINT.CREATE_BRAND, data, {
    headers
  });
};

export const searchBrand = (query) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.get(
    httpConstants.API_END_POINT.SEARCH_BRAND,
    { query: query },
    { headers }
  );
};

export const getGoogleFonts = ({ family, sort }) => {
  return brandService.post(
    httpConstants.API_END_POINT.GET_FONTS,
    { family, sort },
    {}
  );
};

export const uploadFile = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.post(httpConstants.API_END_POINT.UPLOAD_FILE, formData, {
    headers
  });
};

export const updateUserDetails = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.put(httpConstants.API_END_POINT.UPDATE_USER, data, {
    headers
  });
};

export const getImages = (page = 1, count = 12) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  const params = { count, page };
  return brandService.get(httpConstants.API_END_POINT.GET_IMAGES, params, {
    headers
  });
};

export const uploadImageByUrl = async (imageUrl) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  const data = {
    imageUrl
  };
  return brandService.post(httpConstants.API_END_POINT.IMAGE_URL, data, {
    headers
  });
};
export const updateBrandDetails = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.UPDATE_BRAND}?id=${data?.id}`;
  return brandService.put(url, data?.requestData, { headers });
};

export const getProjectList = (brandId, page = 1, limit = 100) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  let query = {
    id: brandId,
    page: page,
    limit: limit
  };

  const url = `${httpConstants.API_END_POINT.PROJECT_LIST}`;
  return brandService.get(url, query, { headers });
};

export const createProject = (brandId, data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  return brandService.post(
    httpConstants.API_END_POINT.CREATE_PROJECT.replace(":BRAND_ID", brandId),
    data,
    {
      headers
    }
  );
};

export const deleteProject = (projectId) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  let query = {
    id: projectId
  };

  return brandService.delete(
    httpConstants.API_END_POINT.DELETE_PROJECT + `?id=${projectId}`,
    query,
    {
      headers
    }
  );
};

export const getProjectDetalis = (projectId) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  let query = {
    id: projectId
  };

  return brandService.get(
    httpConstants.API_END_POINT.GET_PROJECT_DETAILS + `?id=${projectId}`,
    query,
    {
      headers
    }
  );
};

export const updateProject = (projectId, data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  return brandService.put(
    httpConstants.API_END_POINT.UPDATE_PROJECT.replace(
      ":PROJECT_ID",
      projectId
    ),
    data,
    {
      headers
    }
  );
};

export const getSearchImagesAI = (page = 1, count = 10, query) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  let queryData = {
    count,
    page
  };
  if (query) {
    queryData.query = query;
  }

  return brandService.get(
    httpConstants.API_END_POINT.SEARCH_IMAGE_AI,
    queryData,
    {
      headers
    }
  );
};
export const generatePrompt = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.post(httpConstants.API_END_POINT.PROMPT, data, {
    headers
  });
};
export const generateAdDetails = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return brandService.post(httpConstants.API_END_POINT.AD_DETAILS, data, {
    headers
  });
};
export const regenerateAiImages = (projectId) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.REGENERATE_IMAGES}/${projectId}`;
  return brandService.post(url, projectId, { headers });
};
export const searchProject = (brandId, queryString) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.SEARCH_PROJECTS}?brandId=${brandId}&query=${queryString}&page=1&limit=50&sortBy=1&sortOrder=1`;

  return brandService.get(url, {}, { headers });
};
export const upgradeSubscriptionPlan = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.post(
    httpConstants.API_END_POINT.UPGRADE_SUBSCRIPTION,
    data,
    {
      headers
    }
  );
};
export const cancelSubscriptionPlan = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.post(
    httpConstants.API_END_POINT.CANCEL_SUBSCRIPTION,
    data,
    {
      headers
    }
  );
};
export const buyMoreTokens = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.post(
    httpConstants.API_END_POINT.CREATE_CHECKOUT_SESSION,
    data,
    {
      headers
    }
  );
};
export const getInvoiceList = (requestData) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.INVOICE_LIST}?page=${requestData?.page}&limit=${requestData?.limit}`;

  return httpService.get(url, {}, { headers });
};
export const getInvoiceDetails = (invoiceId) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.INVOICE_URL}?invoiceId=${invoiceId}`;

  return httpService.get(url, {}, { headers });
};
export const getListOfNotification = () => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.NOTIFICATION}`;

  return httpService.get(url, {}, { headers });
};
export const markNotificationRead = (data) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.put(
    httpConstants.API_END_POINT.MARK_NOTIFICATION_READ,
    data,
    {
      headers
    }
  );
};
export const updateCardDetails = () => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };
  return httpService.post(
    httpConstants.API_END_POINT.UPDATE_CARD,
    {},
    {
      headers
    }
  );
};
export const createSession = (userId) => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.CREATE_SESSION}/${userId}`;

  return httpService.post(url, {}, { headers });
};
export const getAllTemplates = () => {
  const headers = {
    [httpConstants.HEADERS.AUTHORIZATION]:
      `Bearer ${String(sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)).replace(/"/g, "")}`
  };

  const url = `${httpConstants.API_END_POINT.ALL_TEMPLATES}`;

  return brandService.get(url, {}, { headers });
};
