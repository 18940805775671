/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import React, { useEffect, useState } from "react";
import { getInvoiceList } from "../../services/userService";
import Utils from "../../utility";
import InfiniteScroll from "react-infinite-scroll-component";
import { MoonLoader } from "react-spinners";

const ViewAll = ({ setShowPopup, handleInvoiceClick, selectedInvoice }) => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetchUserInvoiceList = async () => {
    const limit = 8;
    const pageNumber = page + 1;

    const requestData = {
      page: pageNumber,
      limit: limit
    };

    try {
      setLoading(true);
      const [error, response] = await Utils.parseResponse(
        getInvoiceList(requestData)
      );

      if (error || !response) {
        setHasMore(false);
        return;
      }

      if (response?.responseData?.length > 0) {
        setInvoiceList((prevData) => [...prevData, ...response.responseData]);
        setPage(pageNumber);
        if (response.responseData.length < limit) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-grey-23 bg-opacity-50 font-InterRegular backdrop-blur-md">
        <div
          id="scrollableDiv"
          className="h-60vh w-60per overflow-y-auto rounded-15px bg-white shadow-lg scrollbar-hide 2xl:w-45per"
        >
          <InfiniteScroll
            dataLength={invoiceList.length}
            next={fetchUserInvoiceList}
            hasMore={hasMore}
            loader={
              loading && (
                <div className="flex items-center justify-center py-2">
                  <MoonLoader loading={loading} color="#17C471" />
                </div>
              )
            }
            scrollableTarget="scrollableDiv"
            className="hide-scrollbar"
          >
            <div className="p-3">
              <div className="flex w-full justify-end">
                <button onClick={() => setShowPopup(null)}>
                  <img src="/images/close-icon.svg" alt="Close" />
                </button>
              </div>

              <div className="flex flex-col items-center justify-center text-center">
                <h1 className="font-InterBold text-ft32-41.6 text-black-9">
                  Invoices
                </h1>
                <p className="mt-2 w-60per text-center font-InterRegular text-ft18-25.2 font-normal text-grey-14">
                  Here is a list of all your invoices. You can click on each one
                  to view more details, download the PDF, or take any necessary
                  actions.
                </p>
              </div>

              <div className="flex h-full justify-center">
                <div className="mt-4 flex w-60per flex-col space-y-3">
                  {invoiceList.map((invoice, index) => (
                    <div
                      key={index}
                      className={`flex cursor-pointer items-center justify-between rounded-15px px-4 py-2.5 ${
                        selectedInvoice === invoice
                          ? "border border-green-12 hover:border-green-12"
                          : "hover:border-grey-12"
                      }`}
                      onClick={() =>
                        invoice?.invoiceId !== null &&
                        handleInvoiceClick(
                          invoice?.invoiceId,
                          invoice.invoiceUrl
                        )
                      }
                    >
                      <div className="flex flex-col">
                        <span className="text-ft14-19.6 font-bold text-grey-16">
                          {Utils.formatDateInStringForm(
                            invoice?.createdAt || "N/A"
                          )}
                        </span>
                        <span className="text-ft12-16 font-bold text-grey-14">
                          {invoice?.invoiceId || "N/A"}
                        </span>
                      </div>
                      <div className="flex items-center space-x-3">
                        <span className="text-ft12-16 font-bold text-grey-14">
                          ${(invoice?.price / 100).toFixed(2) || "N/A"}
                        </span>
                        {invoice.invoiceId && (
                          <div
                            className={`flex items-center space-x-1 text-ft10-16 ${
                              selectedInvoice === invoice
                                ? "text-green-12"
                                : "text-grey-12"
                            }`}
                          >
                            <img
                              src={
                                selectedInvoice === invoice
                                  ? "/images/pdf-selected.svg"
                                  : "/images/pdf-icon.svg"
                              }
                              alt="pdf"
                              className="h-4"
                            />
                            <span>PDF</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default ViewAll;
