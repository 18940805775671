import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";
const Welcome = lazy(() => import("./modules/onboarding/welcome"));
const Started = lazy(() => import("./modules/onboarding/starter"));
const Business = lazy(() => import("./modules/onboarding/business"));
const Home = lazy(() => import("./modules/welcome/"));
const Discovery = lazy(() => import("./modules/onboarding/discovery"));
const BusinessModal = lazy(() => import("./modules/popup/business"));
const AuthPage = lazy(() => import("./modules/auth"));
const AdLibrary = lazy(() => import("./modules/adLibrary"));

import CommonLoader from "./common/components/loading";
import Callback from "./modules/auth/callback";
import BrandDetailsComponent from "./modules/brandDetails";
import PrivateRoute from "./privateRoute";
import UserDetailsComponent from "./modules/manageAccount";
import { sessionManager } from "./managers/sessionManager";
import { httpConstants } from "./constants";
import FinalReview from "./modules/welcome/project/finalReview";
import Editor from "./modules/welcome/project/editor";
import PreviewProject from "./modules/welcome/project/previewProject";
import { useSelector } from "react-redux";

function AuthHandler() {
  const { type } = useParams();
  const [isValidType, setIsValidType] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const validTypes = ["login", "register", "home"]; // Move inside useEffect
    const defaultType = "register";
    const updatedType = type || defaultType;

    setIsValidType(validTypes.includes(updatedType));

    if (!validTypes.includes(updatedType)) {
      navigate(`/auth/${defaultType}`, { replace: true });
    }
  }, [type, navigate]);

  return <>{isValidType && <AuthPage />}</>;
}

const Router = () => {
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const token = sessionManager.getDataFromCookies(
    httpConstants.HEADERS.AUTH0_ID_TOKEN
  );
  const user = useSelector((state) => state?.user);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("info"));
    if (
      token &&
      user?.brandList?.length === 0 &&
      userData?.authId?.length === 0
    ) {
      navigate("/welcome");
    }

    if (token && pathName.includes("auth") && userData?.authId?.length > 0) {
      navigate("/home/dashboard");
    }
    if (!token && !pathName.includes("auth") && !user?.brandList?.length < 0) {
      navigate("/auth/register", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);
  let isLoading = useSelector((state) => state.user.loading);
  return (
    <div className=" w-full overflow-y-auto bg-grey-18">
      {isLoading && <CommonLoader />}
      <Suspense fallback={<CommonLoader />}>
        <Routes>
          <Route path="/auth/:type" element={<AuthHandler />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/home/:id" element={<PrivateRoute element={Home} />} />

          <Route
            path="/dashboard/brand/details/:id/:id?/:id?"
            element={<PrivateRoute element={BrandDetailsComponent} />}
          />
          <Route
            path="/user-details"
            element={<PrivateRoute element={UserDetailsComponent} />}
          />
          <Route
            path="/ad-library"
            element={<PrivateRoute element={AdLibrary} />}
          />
          <Route
            path="/settings"
            element={<PrivateRoute element={UserDetailsComponent} />}
          />
          <Route path="/started" element={<Started />} />
          <Route path="/business" element={<Business />} />
          <Route path="/modal/business" element={<BusinessModal />} />
          <Route path="/discovery" element={<Discovery />} />
          <Route
            path="/editor/:id?/:id?"
            element={<PrivateRoute element={Editor} isLayout={false} />}
          />
          <Route
            path="/preview-project"
            element={<PrivateRoute element={PreviewProject} />}
          />
          <Route
            path="/dashboard/brand/details/project/finalReview/:id?/:id?"
            element={<PrivateRoute element={FinalReview} />}
          />

          <Route path="*" element={<Navigate to="/auth/:type" replace />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default Router;
