import React, { useState } from "react";
import BrandDetails from "./brandDetails";
import { MoonLoader } from "react-spinners";

const BrandDetailsComponent = () => {
  const [manage, setManage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isProject, setIsProject] = useState(null);
  const [projectButtonVisible, setprojectButtonVisible] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);

  return (
    <div
      className="w-full"
      onClick={() => {
        dropdown && setDropdown(false), manage && setManage(false);
      }}
    >
      <MoonLoader
        loading={loading || detailsLoading}
        color="#17C471"
        cssOverride={{
          position: "absolute",
          zIndex: 1000,
          top: "45%",
          left: "50%"
        }}
      />
      <BrandDetails
        setManage={setManage}
        manage={manage}
        setLoading={setLoading}
        dropdown={dropdown}
        setDropdown={setDropdown}
        isProject={isProject}
        setIsProject={setIsProject}
        projectButtonVisible={projectButtonVisible}
        setprojectButtonVisible={setprojectButtonVisible}
        setDetailsLoading={setDetailsLoading}
      />
    </div>
  );
};

export default BrandDetailsComponent;
