import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BeatLoader } from "react-spinners";
import { getBrandsList } from "../../services/userService";
import Utils from "../../utility";
import ClickOutside from "./clickOutside";

const BrandListDropdown = ({ onSelectFn = () => {}, name = null }) => {
  const [brandsList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [page, setPage] = useState(0);

  const getBrandList = async () => {
    const limit = 20;
    const nextPage = page + 1;

    const requestData = {
      page: nextPage,
      limit: limit
    };
    try {
      setLoading(true);
      let [error, response] = await Utils.parseResponse(
        getBrandsList(requestData)
      );
      if (error || !response) {
        setLoading(false);
        return;
      }

      if (response?.responseData.length > 0) {
        setBrandList((prevData) => [...prevData, ...response.responseData]);
        setPage(nextPage);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBrandList();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <ClickOutside onClickOutside={() => setDropdown(false)} isOpen={dropdown}>
        <div
          className=" relative flex h-[33.39px] w-[341px] cursor-pointer items-center rounded-3xl border-0.5px border-grey-9 bg-white p-2 pl-4"
          onClick={() => setDropdown(!dropdown)}
        >
          <img src="/images/down-icon.svg" alt="Down icon" />
          <span className=" ml-2 font-InterRegular text-ft14-19.6 text-grey-19">
            {selectedName ? selectedName : name ? name : "Select a brand"}
          </span>
          {dropdown && (
            <div
              id="scroll"
              className={`absolute  right-0 top-8 z-30 h-auto max-h-28 w-full overflow-y-auto rounded-3xl border-0.5px border-grey-9 bg-white p-1 text-ft14-19.6 text-grey-19 scrollbar-hide`}
            >
              <InfiniteScroll
                dataLength={brandsList.length}
                next={getBrandList}
                hasMore={hasMore}
                loader={
                  loading && (
                    <div className="flex items-center justify-center">
                      <BeatLoader loading={loading} color="#17C471" size={8} />
                    </div>
                  )
                }
                className={"overflow-hidden"}
                endMessage={""}
                scrollableTarget="scroll"
              >
                {brandsList?.length > 0 ? (
                  brandsList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="cursor-pointer truncate px-5 py-1 text-grey-16 hover:rounded-[20px] hover:bg-green-12 hover:text-white"
                        onClick={() => {
                          setDropdown(false);
                          onSelectFn(item);
                          setSelectedName(item.brandName);
                        }}
                      >
                        {item?.brandName}
                      </div>
                    );
                  })
                ) : (
                  <div>No list found</div>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

export default BrandListDropdown;
