import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../common/components/header";
import { updateUserDetails, uploadFile } from "../../services/userService";
import Utils from "../../utility";
import UserPage from "./userPage";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetail } from "../../slices/userSlice";

const UserDetails = ({
  setLoading,
  dropdown,
  setDropdown,
  setManage,
  manage
}) => {
  const userInfo = useSelector((state) => state?.user?.info);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  // const [userInfo, setUserInfo] = useState(userDetails?.info);
  const [query, setQuery] = useState("");
  const [tab, setTab] = useState("ACCOUNT");
  const [inputs, setInputs] = useState({ image: "" });
  // const location = useLocation();
  // const currentPath = location.pathname;

  const uploadImage = async (file) => {
    setLoading(true);
    let [error, response] = await Utils.parseResponse(uploadFile(file));
    if (error || !response) {
      setLoading(false);
      return;
    }
    setLoading(false);
    return response?.responseData;
  };
  const handleChange = async (e) => {
    if (e.target.type === "file") {
      const file = e.target.files[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        let URL = await uploadImage(file);
        if (URL && URL.startsWith("https")) {
          setInputs((prev) => ({ ...prev, image: URL }));
          toast.success("Image uploaded successfully.");
          const businessType = "individual";
          const [error, response] = await Utils.parseResponse(
            updateUserDetails({
              profileImage: URL,
              businessType
            })
          );
          if (error || !response.success) {
            toast.error("Failed to update user profile. Please try again.");
          } else {
            const updatedProfileImage = response.responseData.profileImage;
            const updatedUser = {
              ...userInfo,
              profileImage: updatedProfileImage
            };
            dispatch(updateUserDetail(updatedUser));
            // localStorage.setItem("info", JSON.stringify(updatedUser));
            // setUserInfo(updatedUser);
            setInputs((prev) => ({ ...prev, image: updatedProfileImage }));
            toast.success("User profile updated successfully.");
            setQuery(updatedProfileImage);
          }
        }
      } else {
        toast.error("Image type should be jpg or png.");
      }
    }
  };
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getFullName = () => {
    const firstName = userInfo?.profile?.firstName || user?.given_name || "";
    const lastName = userInfo?.profile?.lastName || user?.family_name || "";
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  };

  const fullName = getFullName();
  const displayName =
    fullName.length > 30 ? `${fullName.substring(0, 30)}...` : fullName;
  return (
    <div className="w-full">
      <div
        style={{
          background:
            "linear-gradient(180deg, #021327 0%, #04264E 0.01%, #000000 100%)",
          marginLeft: 32,
          marginRight: 32,
          marginTop: 20,
          borderRadius: 16,
          height: 150
        }}
      >
        <Header
          user={user}
          navigate={navigate}
          dropdown={dropdown}
          setDropdown={setDropdown}
          manage={manage}
          setManage={setManage}
          query={query}
          setQuery={setQuery}
          setLoading={setLoading}
          isFromUserDetails={true}
        />
      </div>

      <div className={` relative px-8 py-2 pt-6`}>
        <div className={`size-full overflow-hidden rounded-2xl bg-grey-18 p-2`}>
          <UserPage tab={tab} />
        </div>
        <div className="absolute top-[-48px] z-20 w-[95.5%] px-8">
          <div
            style={{
              background:
                "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
              height: 116,
              width: "100%",
              borderRadius: 16,
              boxShadow: "0px 2px 5.5px 0px #00000005",
              padding: "10px 10px 10px 30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backdropFilter: "blur(8px)"
            }}
          >
            <div className="flex items-center">
              <div className={`relative size-81px`}>
                <div className="absolute -right-1 -top-1 flex size-6.5 cursor-pointer items-center justify-center rounded-md bg-white p-1.5">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    name="image"
                    id="image"
                    alt="image Picker"
                    className="hidden"
                    onChange={(e) => handleChange(e)}
                  />
                  <label htmlFor="image" className="cursor-pointer">
                    <img src="/images/pen-icon.svg" className="size-3" />
                  </label>
                </div>
                <label htmlFor="image" className="cursor-pointer">
                  {userInfo?.profileImage ? (
                    <img
                      src={
                        inputs.image ||
                        (userInfo && userInfo.profileImage !== ""
                          ? userInfo.profileImage
                          : user?.picture)
                      }
                      alt="logo"
                      className="size-full rounded-xl object-cover"
                    />
                  ) : (
                    <div className="flex size-full items-center justify-center rounded-xl bg-green-12 font-InterBold text-ft36-40 text-white">
                      {user?.name?.charAt(0)?.toUpperCase()}
                    </div>
                  )}
                </label>
              </div>

              <div className="ml-7 flex h-auto flex-col">
                <span className={` font-InterBold text-ft24-32 text-black-9`}>
                  <Fragment>
                    {userInfo?.profile?.firstName ||
                    userInfo?.profile?.lastName ? (
                      <div className="w-auto truncate">
                        <span>{displayName}</span>
                      </div>
                    ) : user?.given_name && user?.family_name ? (
                      <div className="w-auto truncate">
                        <span>{displayName}</span>
                      </div>
                    ) : (
                      `${capitalize(user?.nickname || "")}`
                    )}
                  </Fragment>
                </span>
                <span className=" mb-2 mt-1 font-InterRegular text-ft14-19.6 text-grey-22">
                  <Fragment>
                    {userInfo && userInfo?.profile?.email
                      ? userInfo?.profile.email
                      : user && user?.email
                        ? user.email
                        : "No email found"}
                  </Fragment>
                </span>
              </div>
            </div>

            <div className="flex items-center justify-between gap-x-4">
              <div
                className={` h-8.7 w-[137.43px] cursor-pointer rounded-2xl ${tab === "ACCOUNT" && "bg-white shadow-dark"} flex items-center justify-center font-InterSemibold text-ft14-19.6 text-black-0`}
                onClick={() => {
                  setTab("ACCOUNT");
                }}
              >
                ACCOUNT
              </div>
              <div
                onClick={() => {
                  setTab("BILLING");
                }}
                className={` h-8.7 w-[137.43px] cursor-pointer rounded-2xl ${tab === "BILLING" && "bg-white shadow-dark"} flex items-center justify-center font-InterSemibold text-ft14-19.6 text-black-0`}
              >
                BILLING
              </div>
              {/* {!currentPath?.includes("settings") && (
                <div
                  className={` h-8.7 w-[137.43px] cursor-pointer rounded-2xl ${tab === "INTEGRATIONS" && "bg-white shadow-dark"} flex items-center justify-center font-InterSemibold text-ft14-19.6 text-black-0`}
                >
                  INTEGRATIONS
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
