import React, { useState } from "react";
import ProjectType from "./projectType";
import ProjectDetails from "./projectDetails";
import FinalReview from "./finalReview";
import AllTemplates from "./allTemplates";

export default function ImportBrands({
  setprojectButtonVisible,
  setStep,
  step,
  setImportProject,
  brandDetails,
  projectList
}) {
  const [projectTypeState, setProjectTypeState] = useState({
    projectType: "",
    platformName: "",
    goal: "",
    platformList: [],
    goalList: []
  });
  const [projectDetailsState, setProjectDetailState] = useState({
    projectName: "",
    projectDescription: "",
    adType: "",
    imageType: "",
    minAge: 18,
    maxAge: 64,
    gender: "male",
    age: "",
    interest: ""
  });
  const [projectDetailsGeneratedData, setProjectDetailsGeneratedData] =
    useState({});

  const getComponents = () => {
    switch (step) {
      case 1:
        return (
          <ProjectType
            setImportProject={setImportProject}
            projectTypeState={projectTypeState}
            setProjectTypeState={setProjectTypeState}
            setStep={setStep}
            setprojectButtonVisible={setprojectButtonVisible}
            brandDetails={brandDetails}
          />
        );
      case 2:
        return (
          <ProjectDetails
            setStep={setStep}
            setprojectButtonVisible={setprojectButtonVisible}
            projectTypeState={projectTypeState}
            projectDetailsState={projectDetailsState}
            setProjectDetailState={setProjectDetailState}
            brandDetails={brandDetails}
            projectDetailsGeneratedData={projectDetailsGeneratedData}
            setProjectDetailsGeneratedData={setProjectDetailsGeneratedData}
            projectList={projectList}
          />
        );
      case 3:
        return <FinalReview setStep={setStep} />;
      case 4:
        return (
          <AllTemplates
            setStep={setStep}
            projectDetailsState={projectDetailsState}
            projectDetailsGeneratedData={projectDetailsGeneratedData}
            brandDetails={brandDetails}
          />
        );

      default:
        return null;
    }
  };

  return <div>{getComponents()}</div>;
}
