import React from "react";
import { httpConstants } from "../../constants";
import { sessionManager } from "../../managers/sessionManager";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
  updateUserDetail
} from "../../slices/userSlice";
import Utils from "../../utility";
import {
  upgradeSubscriptionPlan,
  verifyUserByAuth
} from "../../services/userService";

const UpgradeSubscription = ({
  handleUpgradeSubscriptionPopup,
  updateSubscriptionDetails,
  currentPlanOpen,
  handleSwitchCurrentPlan
}) => {
  const dispatch = useDispatch();
  const priceId = useSelector(
    (state) => state?.user?.info?.subscriptionDetails?.priceId
  );
  const updateSubscriptionToCurrent = async () => {
    const token = String(
      sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)
    );

    if (!priceId) {
      Utils.failureToastMessage("You can't switch to your current plan");
      return;
    }

    try {
      dispatch(showLoader());
      const response = await upgradeSubscriptionPlan({
        priceId: priceId
      });
      if (response) {
        if (response?.responseCode === 200) {
          dispatch(showLoader());
          setTimeout(async () => {
            const authDetails = await verifyUserByAuth(token);
            dispatch(updateUserDetail(authDetails?.responseData));
            Utils.successToastMessage(
              "You have successfully switched to your current plan"
            );
            handleSwitchCurrentPlan();
            dispatch(hideLoader());
          }, 10000);
        } else {
          Utils.failureToastMessage(
            "An issue occurred while switching to your current plan"
          );
          handleSwitchCurrentPlan();
          return;
        }
      }
    } catch (err) {
      Utils.failureToastMessage(
        "An error occurred while switching to your current plan"
      );
      handleSwitchCurrentPlan();
    } finally {
      // DO SOMETHING HERE
    }
  };
  return (
    <div className="fixed right-0 top-0 z-[1000] flex size-full items-center justify-center bg-blurDark px-15 backdrop-blur-sm">
      <div className="w-166.2 cursor-pointer rounded-15px bg-white px-7 py-5">
        <img
          onClick={
            currentPlanOpen
              ? handleSwitchCurrentPlan
              : handleUpgradeSubscriptionPopup
          }
          className="-mr-2 ml-auto cursor-pointer"
          src="/images/close-icon.svg"
          alt="Close"
        />
        <h1 className=" text-center font-InterBold text-ft32-41.6 text-grey-24">
          {currentPlanOpen
            ? "Confirm Switch Subscription"
            : "Confirm Upgrade Subscription"}
        </h1>
        <p className="mt-5 text-center font-InterRegular text-ft14-19.6 text-grey-14">
          {currentPlanOpen
            ? "Are you sure you want to switch to your current plan? It suits your needs more effectively."
            : "Are you sure you want to upgrade your subscription? The new plan will take effect once your current subscription expires."}
        </p>
        <div className=" my-4 flex justify-center gap-7">
          <button
            onClick={
              currentPlanOpen
                ? handleSwitchCurrentPlan
                : handleUpgradeSubscriptionPopup
            }
            className="flex h-8.7 w-51.7 items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent font-InterBold text-ft14-19.6 text-grey-14"
          >
            CANCEL
          </button>
          <button
            onClick={
              currentPlanOpen
                ? updateSubscriptionToCurrent
                : updateSubscriptionDetails
            }
            className="flex h-8.7 w-51.7 items-center justify-center rounded-3xl bg-green-12 font-InterBold text-ft14-19.6 text-white"
          >
            {currentPlanOpen ? "SWITCH" : "UPGRADE"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradeSubscription;
