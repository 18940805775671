import React, { useEffect, useState, Fragment } from "react";
import TextArea from "../../../common/components/textArea";
import FloatingLabel from "../../../common/components/inputs/FloatingLabel";
import Utils from "../../../utility";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getProjectDetalis,
  updateProject
} from "../../../services/userService";
import Header from "../../../common/components/header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../slices/userSlice";

function FinalReview() {
  const [user] = useState(JSON.parse(localStorage.getItem("userDetails")));
  // eslint-disable-next-line no-unused-vars
  const brandDetails = useSelector((state) => state?.user?.brandDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = window.location.href;

  const projectTemplateIds = currentUrl.split("/");

  const [projectDetails, setProjectDetalis] = useState({});
  const [platformName, setPlatformName] = useState();
  const [manage, setManage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [templateId, setTemplateId] = useState("");
  const primaryTextArr = projectDetails?.generatedData?.primaryText ?? [];
  const handleGetProjectDetails = async (projectId) => {
    try {
      dispatch(showLoader());
      const [error, response] = await Utils.parseResponse(
        getProjectDetalis(projectId)
      );

      if (error || !response) {
        toast.error("Error");
        return;
      }

      setProjectDetalis(response?.responseData);
      setPlatformName(response?.responseData?.platformName);
    } catch (err) {
      console.error("An error occurred while fetching project details:", err);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleProjectUpdate = async () => {
    if (
      projectDetails?.generatedData?.primaryText !== null &&
      (projectDetails?.generatedData?.primaryText[0] ||
        projectDetails?.generatedData?.primaryText[0]?.length === 0) &&
      projectDetails?.generatedData?.primaryText[0]?.length < 10
    ) {
      Utils.failureToastMessage(
        "Minimum 10 characters is required for primary text"
      );
      return;
    }
    if (projectDetails.projectDescription?.length < 10) {
      Utils.failureToastMessage(
        "Minimum 10 characters is required for description"
      );
      return;
    }
    if (
      (projectDetails?.generatedData?.headlines[0] ||
        projectDetails?.generatedData?.headlines[0]?.length === 0) &&
      projectDetails?.generatedData?.headlines[0]?.length < 10
    ) {
      Utils.failureToastMessage(
        "Minimum 10 characters is required for headlines"
      );
      return;
    }
    if (
      (projectDetails?.generatedData?.headlines[1] ||
        projectDetails?.generatedData?.headlines[1]?.length === 0) &&
      projectDetails?.generatedData?.headlines[1]?.length < 10
    ) {
      Utils.failureToastMessage(
        "Minimum 10 characters is required for headlines"
      );
      return;
    }
    if (
      (projectDetails?.generatedData?.headlines[2] ||
        projectDetails?.generatedData?.headlines[2]?.length === 0) &&
      projectDetails?.generatedData?.headlines[2]?.length < 10
    ) {
      Utils.failureToastMessage(
        "Minimum 10 characters is required for headlines"
      );
      return;
    }

    try {
      dispatch(showLoader());
      let data = {
        ...projectDetails,
        isDraft: false
      };
      // eslint-disable-next-line no-unused-vars
      const [error, response] = await Utils.parseResponse(
        updateProject(projectTemplateIds[8], data)
      );
      if (error) {
        if (error?.response?.data?.message) {
          Utils.failureToastMessage(error?.response?.data?.message);
          return;
        } else {
          Utils.failureToastMessage("Some error occured");
          return;
        }
      } else {
        await handleGetProjectDetails(projectTemplateIds[8]);
        Utils.successToastMessage("Project Updated Successfully");
        navigate(`/dashboard/brand/details/${projectDetails?.brandId?._id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
  const redirectBackToEditor = () => {
    navigate(`/editor/${projectTemplateIds[9]}/${projectTemplateIds[8]}`);
  };
  const redirectToPage = () => {
    // navigate(`/editor/${projectTemplateIds[9]}/${projectTemplateIds[8]}`);
    if (
      projectDetails?.images?.length == 0 &&
      projectDetails?.selectedImage &&
      !projectDetails?.createdAdJson
      // projectDetails?.isDraft
    ) {
      navigate(
        `/dashboard/brand/details/${projectDetails?.brandId?._id}/${projectTemplateIds[8]}`
      );
    } else if (
      projectDetails?.selectedImage &&
      projectDetails?.createdAdJson
      // projectDetails?.isDraft
    ) {
      navigate(
        `/editor/${projectDetails?.selectedTemplateId}/${projectTemplateIds[8]}`
      );
    } else if (
      projectDetails?.images?.length > 0 &&
      !projectDetails?.selectedImage &&
      !projectDetails?.createdAdJson
      // projectDetails?.isDraft
    ) {
      navigate(
        `/dashboard/brand/details/${projectDetails?.brandId?._id}/true/${projectTemplateIds[8]}`
      );
    } else if (
      projectDetails?.images?.length > 0 &&
      projectDetails?.selectedImage &&
      !projectDetails?.createdAdJson
      // projectDetails?.isDraft
    ) {
      navigate(
        `/dashboard/brand/details/${projectDetails?.brandId?._id}/${projectTemplateIds[8]}`
      );
    }

    // setStep(4);
  };
  useEffect(() => {
    if (projectTemplateIds) {
      handleGetProjectDetails(projectTemplateIds[8]);
      if (projectTemplateIds[9]) {
        setTemplateId(projectTemplateIds[9]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTION TO DOWNLOAD CREATED IMAGE
  // const downloadImage = () => {
  //   const imageUrl =
  //     projectDetails?.createdImage || projectDetails?.selectedImage;

  //   if (imageUrl) {
  //     const link = document.createElement("a");
  //     link.href = imageUrl;
  //     link.download = "image.jpg";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } else {
  //     console.error("No image available to download");
  //   }
  // };

  return (
    <>
      <div
        className="pb-27.2"
        style={{
          background:
            "linear-gradient(180deg, #021327 0%, #04264E 0.01%, #000000 100%)",
          marginLeft: 32,
          marginRight: 32,
          marginTop: 20,
          borderRadius: 16
        }}
      >
        <Header
          user={user}
          navigate={navigate}
          brandDetails={false}
          setNavigate={(item) =>
            navigate(`/dashboard/brand/details/${item?.id}`)
          }
          dropdown={false}
          setDropdown={() => {}}
          manage={manage}
          setManage={setManage}
          pathname={""}
          query={""}
          setQuery={() => {}}
          setLoading={() => {}}
        />
      </div>
      <div className="z-20 -mt-61px ml-7.7 w-[95.5%] px-8">
        <div
          style={{
            background:
              "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
            height: 116,
            width: "100%",
            borderRadius: 16,
            boxShadow: "0px 2px 5.5px 0px #00000005",
            padding: "20px 54px 20px 32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backdropFilter: "blur(8px)"
          }}
        >
          <div className="flex items-center">
            <div className={`size-81px`}>
              <>
                {brandDetails && brandDetails?.theme?.logo != " " ? (
                  <img
                    src={brandDetails?.theme?.logo}
                    alt="logo"
                    className="h-[80px] w-81px rounded-xl"
                  />
                ) : (
                  <div className="flex size-full items-center justify-center rounded-xl bg-green-12 font-InterBold text-ft36-40 text-white">
                    {brandDetails?.brandName?.charAt(0)?.toUpperCase()}
                  </div>
                )}
              </>
            </div>

            <div className="ml-7 flex h-auto flex-col">
              <span
                className={` font-InterBold text-ft24-32 ${brandDetails ? "text-black-9" : "text-grey-16"}`}
              >
                {brandDetails?.brandName && (
                  <Fragment>
                    <div className="w-52 truncate">
                      <span>{brandDetails?.brandName}</span>
                    </div>
                  </Fragment>
                )}
              </span>
              <span className=" mb-2 mt-1 font-InterRegular text-ft14-19.6 text-grey-22">
                {brandDetails?.businessType && (
                  <Fragment>
                    {brandDetails?.businessType || "No email found"}
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-9 mt-10 h-full bg-grey-18">
        <div className="relative size-full h-[795px] max-w-[997px] rounded-2xl bg-white p-10 pl-16">
          <div className="h-157.5 overflow-y-auto">
            <div className="flex  flex-col">
              <div className=" font-InterBold text-ft24-32 text-[#A0AEC0]">
                {`${platformName}  ${projectDetails?.projectType === "socialPost" ? "Social Post" : projectDetails?.projectType}`}
              </div>
              <div className=" font-Inter text-ft16-19.36 text-[#A0AEC0]">
                {` ${projectDetails?.projectName}`}
              </div>
            </div>
            <div className="flex">
              <div>
                <div className=" mt-6">
                  {primaryTextArr?.map((txt, index) => {
                    return (
                      <div className="mt-7" key={index}>
                        <TextArea
                          key={index.toString()}
                          name={"primaryText"}
                          label={"Primary Text"}
                          height={"h-25.7"}
                          width={"w-175.7"}
                          value={
                            projectDetails.generatedData.primaryText[index]
                          }
                          onChange={(e) => {
                            const newValue = e.target.value;

                            // if (
                            //   newValue.length >= 10 ||
                            //   projectDetails?.generatedData?.primaryText[index]
                            //     ?.length >= 10
                            // ) {
                            setProjectDetalis((preState) => ({
                              ...preState,
                              generatedData: {
                                ...preState.generatedData,
                                primaryText:
                                  preState.generatedData.primaryText?.map(
                                    (text, idx) =>
                                      idx === index ? newValue : text
                                  )
                              }
                            }));
                            // }
                          }}
                          haveStar={true}
                        />
                      </div>
                    );
                  })}
                </div>

                {projectDetails?.generatedData?.headlines?.map((txt, index) => {
                  return (
                    <div className=" mt-6" key={index.toString()}>
                      <FloatingLabel
                        name={"headline"}
                        label={"Headline"}
                        onBoard={true}
                        isStar={true}
                        isSmall={true}
                        height={"h-11.2"}
                        width={"w-175.7"}
                        isInfo={false}
                        isTool={true}
                        value={projectDetails?.generatedData?.headlines[index]}
                        onChange={(e) => {
                          const newValue = e.target.value;

                          // if (
                          //   newValue.length >= 10 ||
                          //   projectDetails?.generatedData?.headlines[index]
                          //     ?.length >= 10
                          // ) {
                          setProjectDetalis((preState) => ({
                            ...preState,
                            generatedData: {
                              ...preState.generatedData,
                              headlines: preState.generatedData.headlines?.map(
                                (text, idx) => (idx === index ? newValue : text)
                              )
                            }
                          }));
                          // }
                        }}
                      />
                    </div>
                  );
                })}

                <div className=" mt-6">
                  <TextArea
                    name={"description"}
                    label={"Description"}
                    height={
                      projectDetails?.platformName !== "Google" &&
                      projectDetails?.platformName !== "Bing"
                        ? "h-25.7"
                        : "h-65"
                    }
                    width={"w-175.7"}
                    value={projectDetails.projectDescription}
                    onChange={({ target: { value } }) => {
                      setProjectDetalis((preState) => ({
                        ...preState,
                        projectDescription: value
                      }));
                    }}
                    haveStar={true}
                  />
                </div>
                <div
                  className={`${projectDetails?.platformName === "Google" || projectDetails?.platformName === "Bing" ? "hidden" : "block"} mt-6`}
                >
                  <span className="text-[#A0AEC0]">Media Asset</span>
                  {projectDetails?.createdImage ||
                  projectDetails?.selectedImage ? (
                    <img
                      className="mt-6 h-79.2 w-97 rounded-lg bg-grey-18 object-contain"
                      src={
                        projectDetails?.createdImage ||
                        projectDetails?.selectedImage
                      }
                    />
                  ) : (
                    <div className=" grid h-79.2 w-97 p-2">
                      {projectDetails?.images && (
                        <div className=" grid h-79.2 w-full grid-cols-2 gap-2 p-2 opacity-30">
                          {" "}
                          <img
                            className="h-46.2 w-full rounded object-cover"
                            src={projectDetails?.images[0]}
                          />
                          <img
                            className="h-46.2 w-full rounded object-cover"
                            src={projectDetails?.images[1]}
                          />
                          <img
                            className="h-46.2 w-full rounded object-cover"
                            src={projectDetails?.images[2]}
                          />
                          <img
                            className="h-46.2 w-full rounded object-cover"
                            src={projectDetails?.images[3]}
                          />{" "}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* {projectDetails?.platformName !== "Google" &&
          projectDetails?.platformName !== "Bing" &&
          (!projectDetails?.selectedImage ||
            projectDetails?.createdAdJson === undefined) ? (
            <div className="mt-7 font-InterBold text-ft16-19.36 text-grey-14">
              You have an unfinished project. Press the edit button to continue
              working on it
            </div>
          ) : null} */}
          <div className="absolute bottom-33px left-63px flex w-full items-center gap-x-10">
            <div
              className=" mt-7 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent px-2 font-InterBold text-ft14-19.6 text-grey-14"
              onClick={() => {
                projectTemplateIds[9] && projectDetails?.isDraft
                  ? redirectBackToEditor()
                  : navigate(
                      `/dashboard/brand/details/${projectDetails?.brandId?._id}`
                    );
              }}
            >
              BACK
            </div>
            {/* {projectDetails?.platformName !== "Google" &&
            projectDetails?.platformName !== "Bing" &&
            (projectDetails?.createdAdJson !== undefined ||
              projectDetails?.isDraft ||
              !projectDetails?.selectedImage) ? (
              <div
                onClick={redirectToPage}
                className={`mt-7 ${!projectDetails?.isDraft ? "hidden" : "flex"} h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent px-2 font-InterBold text-ft14-19.6 text-grey-14`}
              >
                EDIT
              </div>
            ) : null} */}

            {projectDetails?.platformName !== "Google" &&
            projectDetails?.platformName !== "Bing" ? (
              <div
                onClick={redirectToPage}
                className={`mt-7 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-3xl border-2 border-grey-14 bg-transparent px-2 font-InterBold text-ft14-19.6 text-grey-14`}
              >
                EDIT
              </div>
            ) : null}

            {/* {(projectDetails?.isDraft &&
              projectDetails?.createdAdJson !== undefined) ||
            ((projectDetails?.platformName === "Google" ||
              projectDetails?.platformName === "Bing") &&
              projectDetails?.isDraft) ? (
              <div
                className="mt-7 flex h-8.7 w-75 cursor-pointer items-center justify-center rounded-3xl bg-green-12 px-2 font-InterBold text-ft14-19.6 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  handleProjectUpdate();
                }}
              >
                FINISH
              </div>
            ) : null} */}

            {/* <button
              className="mt-7 flex h-8.7 w-37.5 cursor-pointer items-center justify-center rounded-3xl bg-green-12 px-2 font-InterBold text-ft14-19.6 text-white"
              onClick={downloadImage}
            >
              DOWNLOAD
            </button> */}

            <div
              className="mt-7 flex h-8.7 w-75 cursor-pointer items-center justify-center rounded-3xl bg-green-12 px-2 font-InterBold text-ft14-19.6 text-white"
              onClick={(e) => {
                e.preventDefault();
                handleProjectUpdate();
              }}
            >
              FINISH
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinalReview;
